<template>
    <b-modal :active="isComponentModalActive" :width="'90%'"
        @close="closeModal" >
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Envio de template</p>
          </header>
          <b-loading v-if="loading" :is-full-page="false" :can-cancel="false" :active="true"></b-loading>
          <section class="modal-card-body" style="overflow-x: auto !important">
            <div class="columns">
              <div class="column">
                <b-field label="Selecione um template" grouped>
                  <b-select v-model="selectedTemplate" placeholder="" expanded>
                      <option v-for="template in templates" :key="template.id" :value="template">
                          {{ template.name }}
                      </option>
                  </b-select>
                  <b-button v-if="!showingDetails && selectedTemplate" icon-left="chevron-down" class="is-info" outlined @click="() => showingDetails = true">Detalhes</b-button>
                  <b-button v-if="showingDetails && selectedTemplate" icon-left="chevron-up" class="is-info" outlined @click="() => showingDetails = false">Detalhes</b-button>
                </b-field>
                <b-field label="Preencha os valores " v-if="selectedTemplate && (headerVariables.length > 0 || bodyVariables.length > 0 || footerVariables.length > 0)">
                  <div class="column is-one-third">
                    <b-field label="Cabeçalho da mensagem" v-if="selectedTemplate && headerVariables.length > 0">
                      <div v-for="componente in headerVariables" :key="componente.id">
                        <b-field :label="componente.variable">
                          <b-field v-if="componente.type === 'DOCUMENT' || componente.type === 'VIDEO' || componente.type === 'IMAGE'" class="file is-primary" :class="{ 'has-name': !!file }" group-multiline>
                            <b-button class="button is-info" outlined @click="() => viewFile(componente.id)">Visualizar arquivo exemplo</b-button>
                            <div class="control m-auto">
                              <span class="label mx-2 px-2">Ou</span>
                            </div>
                            <b-upload v-model="file" class="file-label">
                              <span class="file-cta">
                                <b-icon class="file-icon" icon="upload"></b-icon>
                                <span class="file-label">Selecione um arquivo</span>
                              </span>
                              <span class="file-name" v-if="file">
                                {{ file.name }}
                              </span>
                            </b-upload>
                          </b-field>
                          <b-autocomplete
                              v-else
                              v-model="header[Number(componente.id) - 1]"
                              :data="autocompleteOptions"
                              placeholder="Informe um texto livre ou selecione uma das sugestôes"
                              open-on-focus
                              expanded
                            ></b-autocomplete>
                        </b-field>
                      </div>
                    </b-field>
                    <b-field label="Corpo da mensagem" v-if="selectedTemplate && bodyVariables.length > 0" grouped>
                      <div v-for="componente in bodyVariables" :key="componente.id" class="column is-narrow">
                        <b-field :label="componente.variable">
                          <b-autocomplete
                              v-model="body[Number(componente.id) - 1]"
                              :data="autocompleteOptions"
                              placeholder="Informe um texto livre ou selecione uma das sugestôes"
                              open-on-focus
                              expanded
                            ></b-autocomplete>
                        </b-field>
                      </div>
                    </b-field>
                    <b-field label="Rodapé da mensagem" v-if="selectedTemplate && footerVariables.length > 0">
                      <div v-for="componente in footerVariables" :key="componente.id">
                        <b-field :label="componente.variable">
                          <b-input v-model="footer[Number(componente.id) - 1]" type="textarea" expanded></b-input>
                        </b-field>
                      </div>
                    </b-field>
                  </div>
                </b-field>
              </div>
              <div class="column is-two-fifths" v-if="selectedTemplate">
                  <TemplateChat :messages="messages" :rooms="rooms" />
              </div>
              <div class="column" v-if="selectedTemplate && showingDetails">
                <b-collapse :open="showingDetails">
                  <template>
                    <b-field label="Nome">
                      <b-input v-model="selectedTemplate.name" readonly></b-input>
                    </b-field>

                    <b-field label="Categoria">
                      <b-input v-model="selectedTemplate.category" readonly></b-input>
                    </b-field>

                    <b-field label="Status">
                      <b-input v-model="selectedTemplate.status" readonly></b-input>
                    </b-field>

                    <b-field label="Componentes">
                      <div class="column">
                        <div v-for="componente in selectedTemplate.components" :key="componente.id">
                          <b-field v-if="componente.type" :label="componente.type">
                            <b-button v-if="componente.format === 'DOCUMENT' || componente.format === 'IMAGE' || componente.format === 'VIDEO'" class="button mb-4 if-info" outlined @click="() => viewFile(componente.example.header_handle[0])">Visualizar arquivo</b-button>
                            <div v-else-if="componente.type === 'BUTTONS'">
                                <div v-for="button in componente.buttons" :key="button.id">
                                  <b-button class="button mb-4 is-info" outlined>{{ button.text }}</b-button>
                                </div>
                            </div>
                            <b-input v-else v-model="componente.text" readonly class="mb-4" type="textarea" expanded></b-input>
                          </b-field>
                        </div>
                      </div>
                    </b-field>
                  </template>
                </b-collapse>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Cancelar
            </button>
            <button class="button is-primary" @click="sendTemplate">
              Enviar mensagem
            </button>
          </footer>
        </div>
    </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import TemplateChat from '../templates/TemplateChat.vue'
import mixin from '../../utils/mixins'
import { enumContentFormat, enumContentType, zeepsVariables } from '../../utils/constants'

export default {
  name: 'ModalSendTemplate',
  components: {
    TemplateChat
  },
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    templates: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      required: true,
      default: true
    }
  },
  data () {
    return {
      selectedTemplate: null,
      messages: [],
      messagesLoaded: true,
      loadingMessages: false,
      rooms: [],
      messagesPage: 1,
      urlPhotoShowing: '',
      showingDetails: false,
      header: [],
      body: [],
      footer: [],
      headerVariables: [],
      bodyVariables: [],
      footerVariables: [],
      file: null,
      fileInfo: {},
      autocompleteOptions: Object.keys(zeepsVariables)
    }
  },
  computed: {
    ...mapGetters(['getUserId'])
  },
  mixins: [mixin],
  methods: {
    ...mapActions([
      'createTicket',
      'getContacts',
      'getDepartments',
      'getTemplates'
    ]),
    viewFile (url) {
      const a = document.createElement('a')
      a.href = url
      a.target = '_blank'
      a.download = 'download.pdf'
      a.click()
    },
    closeModal () {
      this.$emit('closeModal')
    },
    adaptTicketToRoom (template) {
      return {
        ...template,
        roomId: template.template_id,
        roomName: 'Exemplo:',
        currentUserId: this.getUserId,
        unreadCount: 0,
        lastMessage: {
          content: `Ticket: ${template.id}`,
          timestamp: `${this.formatShortDate(template.created_at)}`,
          date: template.created_at
        },
        users: [
          {
            _id: this.getUserId,
            username: 'John Doe'
          },
          {
            _id: template.id,
            username: 'John Snow'
          }
        ],
        typingUsers: []
      }
    },
    adaptMessage (template) {
      let message = ''
      const file = []

      for (let i = 0; i < template.components.length; i++) {
        const component = template.components[i]
        if (component.type === enumContentType.HEADER) {
          if (component.format === enumContentFormat.TEXT) {
            message = this.getModifiedMessage(component.text, this.header)
          }
          if (component.format === enumContentFormat.DOCUMENT) {
            file.push({
              name: 'My File',
              type: 'pdf',
              url: component.example.header_handle[0]
            })
          }
          if (component.format === enumContentFormat.VIDEO) {
            file.push({
              name: 'My Video',
              type: 'video',
              url: component.example.header_handle[0]
            })
          }
          if (component.format === enumContentFormat.IMAGE) {
            file.push({
              name: 'My Image',
              type: 'image',
              url: component.example.header_handle[0]
            })
          }
        }

        if (component.type === enumContentType.BODY) {
          if (message.length > 0) {
            message += '\n\n'
          }
          message += this.getModifiedMessage(component.text, this.body)
        }

        if (component.type === enumContentType.FOOTER) {
          if (message.length > 0) {
            message += '\n\n'
          }
          message += this.getModifiedMessage(component.text)
        }
      }
      return {
        _id: template.template_id,
        content: message,
        timestamp: `${this.formatShortDate(Date.now())}`,
        type: 'text',
        senderId: this.getUserId,
        user: {
          _id: this.getUserId,
          username: 'John Doe'
        },
        files: file
      }
    },
    getVariables (message) {
      const regex = /{{\d+}}/g
      const variables = []
      let match
      while ((match = regex.exec(message))) {
        variables.push({
          id: match[0].replace(/{{|}}/g, ''),
          value: '',
          type: '',
          variable: match[0]
        })
      }
      return variables
    },
    getModifiedMessage (message, examples = []) {
      let newMessage = message.replace(/<br>/g, '\n')
      for (let i = 0; i < examples.length; i++) {
        const example = examples[i]
        const regex = new RegExp(`{{[${i + 1}]}}`, 'g')
        newMessage = newMessage.replace(regex, example || `{{${i + 1}}}`)
      }

      return newMessage
    },
    sendTemplate () {
      if (!this.selectedTemplate) {
        this.$buefy.toast.open({
          message: 'Selecione um template',
          type: 'is-danger'
        })
        return
      }

      if (this.headerVariables.length > 0) {
        console.warn('headerVariables', this.headerVariables)
        if (this.headerVariables && this.headerVariables[0].type === enumContentFormat.DOCUMENT && !this.fileInfo.base64) {
          this.header.push(this.headerVariables[0].value)
        }
        if (this.headerVariables && this.headerVariables[0].type === enumContentFormat.LOCATION && !this.fileInfo.base64) {
          this.header.push(this.headerVariables[0].value)
        }
        if (this.headerVariables && this.headerVariables[0].type === enumContentFormat.IMAGE && !this.fileInfo.base64) {
          this.header.push(this.headerVariables[0].value)
        }
        if (this.headerVariables && this.headerVariables[0].type === enumContentFormat.VIDEO && !this.fileInfo.base64) {
          this.header.push(this.headerVariables[0].value)
        }

        if (this.headerVariables[0].type === enumContentFormat.DOCUMENT && this.fileInfo.base64) {
          if (this.fileInfo.type !== 'application/pdf') {
            this.$buefy.toast.open({
              message: 'O arquivo deve ser do tipo PDF',
              type: 'is-danger'
            })
            return
          }
        } else if (this.headerVariables[0].type === enumContentFormat.IMAGE && this.fileInfo.base64) {
          if (this.fileInfo.type !== 'image/png' || this.fileInfo.type !== 'image/jpeg') {
            this.$buefy.toast.open({
              message: 'O arquivo deve ser do tipo PNG ou JPG',
              type: 'is-danger'
            })
            return
          }
        } else if (this.headerVariables[0].type === enumContentFormat.VIDEO && this.fileInfo.base64) {
          if (this.fileInfo.type !== 'video/mp4') {
            this.$buefy.toast.open({
              message: 'O arquivo deve ser do tipo MP4',
              type: 'is-danger'
            })
            return
          }
        }
      }

      if (
        (this.header.lenght < 0 || this.body.lenght < 0 || this.footer.lenght < 0) &&
        (this.headerVariables.lenght > 0 || this.bodyVariables.lenght > 0 || this.footerVariables.lenght > 0)) {
        this.$buefy.toast.open({
          message: 'Preencha todos os campos',
          type: 'is-danger'
        })
        return
      }

      this.$emit('sendTemplate', {
        header: this.header,
        body: this.body,
        footer: this.footer,
        template: this.selectedTemplate,
        file: this.fileInfo
      })
    }
  },
  watch: {
    file: function (file) {
      this.fileInfo = {}
      const reader = new FileReader()
      // Convert the file to base64 text
      reader.readAsDataURL(file)
      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        this.fileInfo = {
          name: file.name,
          type: file.type,
          size: Math.round(file.size / 1000) + ' kB',
          base64: reader.result.substr(reader.result.indexOf(',') + 1)
        }
        // Push it to the state
      }
    },
    selectedTemplate (val) {
      if (val) {
        this.headerVariables = []
        this.bodyVariables = []
        this.footerVariables = []
        if (this.selectedTemplate && this.selectedTemplate.components) {
          for (let i = 0; i < this.selectedTemplate.components.length; i++) {
            const element = this.selectedTemplate.components[i]
            if (element.type === enumContentType.HEADER) {
              const examples = element.examples.map((v) => v.value)
              if (element.format === enumContentFormat.TEXT) {
                element.example = {
                  header_text: examples
                }
                this.headerVariables = this.getVariables(element.text)
                if (element.default_values && element.default_values.length > 0) {
                  this.header = element.default_values.sort((a, b) => a.order - b.order).map((item) => item.default_value)
                } else {
                  this.header = []
                }
              } else {
                element.example = {
                  header_handle: examples
                }
              }
              if (element.format === enumContentFormat.DOCUMENT) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: enumContentFormat.DOCUMENT,
                  variable: 'Documento'
                })
              }
              if (element.format === enumContentFormat.LOCATION) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: 'LOCATION',
                  variable: 'Localização'
                })
              }
              if (element.format === enumContentFormat.VIDEO) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: enumContentFormat.VIDEO,
                  variable: 'Vídeo'
                })
              }
              if (element.format === enumContentFormat.IMAGE) {
                this.headerVariables.push({
                  id: element.example.header_handle[0],
                  value: element.example.header_handle[0],
                  type: enumContentFormat.IMAGE,
                  variable: 'Imagem'
                })
              }
            } else if (element.type === enumContentType.BODY) {
              const examples = element.examples.map((v) => v.value)
              element.example = {
                body_text: examples
              }
              this.bodyVariables = this.getVariables(element.text)
              if (element.default_values && element.default_values.length > 0) {
                this.body = element.default_values.sort((a, b) => a.order - b.order).map((item) => item.default_value)
              } else {
                this.body = []
              }
            } else if (element.type === enumContentType.FOOTER) {
              this.footerVariables = this.getVariables(element.text)
            }
          }
        }
        this.rooms = [this.adaptTicketToRoom(this.selectedTemplate)]
        this.messages = [this.adaptMessage(this.selectedTemplate)]
      }
    },
    header: function (val) {
      if (this.selectedTemplate && this.selectedTemplate.components) {
        this.messages = [this.adaptMessage(this.selectedTemplate)]
      }
    },
    body: function (val) {
      if (this.selectedTemplate && this.selectedTemplate.components) {
        this.messages = [this.adaptMessage(this.selectedTemplate)]
      }
    },
    footer: function (val) {
      if (this.selectedTemplate && this.selectedTemplate.components) {
        this.messages = [this.adaptMessage(this.selectedTemplate)]
      }
    }
  }
}
</script>
