<template>
  <div>
    <section>
      <b-modal
        :active="isComponentModalActive"
        @close="closeModal"
        has-modal-card
      >
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalTitle }}</p>
          </header>
          <section class="modal-card-body">
            <b-field v-if="isCreatingNewTicket" label="Número de origem">
              <b-select
                placeholder=""
                v-model="wwPhoneSelected"
                expanded
              >
                <option
                  v-for="setting in getSettings"
                  :value="setting.phone"
                  :key="setting.phone"
                >
                  {{ setting.name ? setting.name + ' - ' + setting.phone : setting.phone }}
                </option>
              </b-select>
            </b-field>
            <b-field v-if="isCreatingNewTicket" label="Departamento">
              <b-select
                placeholder=""
                v-model="departmentSelected"
                expanded
              >
                <option
                  v-for="dpt in getDepartments"
                  :value="dpt"
                  :key="dpt.id"
                >
                  {{ dpt.name }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Contatos disponíveis">
              <b-autocomplete
                class="min-300"
                v-model="filter"
                placeholder="Selecione um contato"
                :keep-first="false"
                :open-on-focus="true"
                :data="contacts"
                field="nameAndPhone"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="option => (contactSelected = option)"
                :clearable="true"
                @select-header="emitAddContact"
                :selectable-header="true"
              >
                <template #header>
                  <a><span>Adicionar contato</span></a>
                </template>
              </b-autocomplete>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
            <button class="button is-primary" @click="selectContact">
              Selecionar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import debounce from 'debounce'
import { mapActions } from 'vuex'
import { API_TYPE } from '../../utils/constants'

export default {
  name: 'ModalCreateTicket',
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    isCreatingNewTicket: {
      type: Boolean,
      required: true
    },
    settings: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    templates: {
      type: Array,
      required: false
    }
  },
  data () {
    return {
      contactSelected: null,
      departmentSelected: null,
      wwPhoneSelected: null,
      filter: '',
      contacts: [],
      isFetching: false,
      filteredDepartments: [],
    }
  },
  watch: {
    wwPhoneSelected (newValue) {
      this.filteredDepartments = this.departments.filter(department => department.ww_phone === newValue || !department.ww_phone || !newValue)
      this.departmentSelected = (this.filteredDepartments.length === 1 && this.filteredDepartments[0]) ? this.filteredDepartments[0] : null
    }
  },
  created () {
    this.wwPhoneSelected = this.settings[0] ? this.settings[0].phone : null
  },
  computed: {
    getSettings () {
      if (this.templates && this.templates.length) {
        return this.settings
      } else {
        return this.settings.filter(s => s.api_type === API_TYPE.WWEBJS)
      }
    },
    getDepartments () {
      return this.filteredDepartments.filter(d => d.enabled)
    }
  },
  methods: {
    ...mapActions(['fetchContactsWithFilter']),
    getAsyncData: debounce(function (name) {
      this.contacts = []
      if (!name.length) {
        return
      }
      this.isFetching = true
      this.fetchContactsWithFilter({ limit: 15, filter: name })
        .then(({ data:{ results } }) => {
          results.forEach(item =>
            this.contacts.push({
              ...item,
              nameAndPhone: `${item.name} - ${item.phone}`
            })
          )
        })
        .catch(error => {
          this.contacts = []
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
    selectContact () {
      if (this.isCreatingNewTicket && !this.departmentSelected) {
        this.$buefy.dialog.alert({
          title: 'Departamento não selecionado',
          message:
            'É necessário selecionar um <b>departamento</b> caso queira prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }
      if (!this.contactSelected) {
        this.$buefy.dialog.alert({
          title: 'Contato não selecionado',
          message:
            'É necessário selecionar um <b>contato</b> caso queira prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }

      if (this.isCreatingNewTicket) {
        this.$buefy.dialog.confirm({
          title: 'Criar ticket',
          message: `Criar novo ticket no departamento <b>${this.departmentSelected.name}</b> para o contato <b>${this.contactSelected.name}</b>?`,
          confirmText: 'Criar',
          type: 'is-success',
          hasIcon: true,
          cancelText: 'Cancelar',
          onConfirm: () => this.emitContactSelected({ contact: this.contactSelected, department: this.departmentSelected })
        })
      } else {
        this.emitContactSelected(this.contactSelected)
      }
    },
    emitContactSelected (contactSelected) {
      this.$emit('contactSelected', {
        ...contactSelected,
        ww_phone: this.wwPhoneSelected
      })
    },
    emitAddContact () {
      this.$emit('addContact')
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.min-300 {
  min-width: 300px;
}

.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.modal-card-foot {
  justify-content: flex-end !important;
}
</style>
