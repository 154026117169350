<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" :width="640">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Adicionar Contatos</p>
          </header>
          <section class="modal-card-body">
            <div v-for="contact in contacts" :key="contact.phone" class="card">
              <div class="card-content contact-card">
                <div class="content">
                  <p><em>Nome</em>: {{ contact.name }} <br>
                  <em>Telefone</em>: {{ contact.phone }}</p>
                </div>
                <div class="add-contact">
                  <button class="button is-success" type="button" @click="addContact(contact)">
                    Adicionar
                  </button>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ModalContactCards',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    contacts: {
      type: Array,
      required: false
    }
  },
  methods: {
    addContact (contact) {
      this.$emit('addContact', contact)
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
  .contact-card {
    display: table;
  }

  .contact-card .content {
    width: 600px;
    display: table-cell;
  }

  .contact-card .add-contact {
    display: table-cell;
  }
</style>
