<template>
  <b-sidebar
    type="is-dark"
    :open="isInternalChatActive"
    :fullheight="true"
    :fullwidth="isFullPage"
    :mobile="'fullwidth'"
    :right="true"
    :can-cancel="[]"
    @close="closeInternalChat"
    >
      <div class="m-2 columns">
        <div class="column if-half has-text-left">
          <b-button class="mr-4" v-if="!isFullPage" type="is-primary" icon-right="chevron-left" @click="toggleDrawer" />
          <b-button class="mr-4" v-if="isFullPage" type="is-primary" icon-right="chevron-right" @click="toggleDrawer" />
          <b-button class="mr-4" type="is-danger" icon-right="close" @click="closeInternalChat" />
        </div>
        <div class="column if-half has-text-right">
          <b-dropdown custom position="is-bottom-left">
            <template #trigger>
              <b-button class="mr-4" type="is-light" :outlined="getUnreadCount === 0" icon-right="bell" />
            </template>
            <b-dropdown-item
            aria-role="listitem"
            v-for="notification in getNotificationDetails"
            :key="notification.roomId"
            @click="selectRoom(notification.roomId)"
            >
              <div class="media">
                  <b-icon class="media-left" :icon="notification.icon"></b-icon>
                  <div class="media-content">
                    <div class="content">
                      <p>
                        <strong>{{ notification.title }}</strong>
                        <br>
                        {{ notification.message }}
                        <br>
                        <small>{{ notification.timestamp }}</small>
                      </p>
                    </div>
                  </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div id="target" v-on:drop="dropHandler" v-on:dragover="dragoverHandler" class="chat-window">
        <b-loading v-if="loading.rooms" :is-full-page="true" v-model="loading.rooms" :can-cancel="false"></b-loading>
        <b-loading v-if="changingFilter" :is-full-page="true" v-model="changingFilter" :can-cancel="false"></b-loading>
        <b-loading v-if="selectingAttendant" :is-full-page="true" v-model="selectingAttendant"
          :can-cancel="false"></b-loading>

        <DragAndDropFiles v-if="isDragging" :selectedRoom="selectedRoom" v-on:closeDrag="closeDrag"
          :isDragging="isDragging" />
        <chat-window
          ref="ChatWindow"
          class=""
          @fetch-messages="fetchMessages"
          @send-message="sendMessage"
          @delete-message="deleteMessage"
          @add-room="createChatHandler"
          @room-info="() => { isModalChatDetailsOpen = true }"
          @open-user-tag="handleOpenUserTag"
          @typing-message="handleTypingMessage"
          :current-user-id="getUserId"
          :rooms="getRooms"
          :theme="'dark'"
          :room-id="selectedRoom ? selectedRoom.roomId : null"
          :messages="messages"
          :messages-loaded="messagesLoaded"
          :loading-rooms="loading.rooms"
          :rooms-loaded="loaded.rooms"
          height="calc(100vh - 121px)"
          :responsive-breakpoint="breakpoint"
          :show-footer="canShowFooter"
          :show-reaction-emojis="false"
          :show-audio="true"
          :room-info-enabled="true"
          :username-options="{minUsers: 3, currentUser: false}"
          :message-actions="messageActions"
          @message-action-handler="messageActionHandler"
          :text-messages="{
            ROOMS_EMPTY: 'Sem chats',
            ROOM_EMPTY: 'Nenhum chat selecionado',
            NEW_MESSAGES: 'Novas mensagens',
            MESSAGE_DELETED: 'Mensagem apagada',
            MESSAGES_EMPTY: 'Nenhuma mensagem',
            CONVERSATION_STARTED: 'A conversa começou em:',
            TYPE_MESSAGE: 'Digite sua mensagem',
            SEARCH: 'Procurar chat',
            IS_ONLINE: 'está online',
            LAST_SEEN: 'Última visualização ',
            IS_TYPING: 'está digitando...'
          }"
        />
      </div>
      <ModalCreateChat
      v-if="isModalCreateChatOpen"
      :modal-title="'Começar nova conversa'"
      :isCreatingNewChat="true"
      :attendants="allAttendantsEnabled"
      :departments="allDepartments"
      :isComponentModalActive="isModalCreateChatOpen"
      v-on:closeModal="isModalCreateChatOpen = false"
      v-on:sendChat="sendChat"
      />
      <ModalChatDetails
      v-if="isModalChatDetailsOpen"
      :chat="selectedRoom"
      :isComponentModalActive="isModalChatDetailsOpen"
      v-on:closeModal="isModalChatDetailsOpen = false"
      v-on:addParticipant="handleAddParticipant"
      v-on:removeParticipant="removeParticipant"
      v-on:exitGroup="exitGroup"
      v-on:deleteChat="deleteChat"
      v-on:startChat="startChat"
      v-on:changeParticipantPermission="changeParticipantPermission"
      />

      <ModalChatParticipantOptions
      v-if="isModalChatParticipantOptionsOpen"
      :participant="selectedUserTag"
      :isComponentModalActive="isModalChatParticipantOptionsOpen"
      v-on:closeModal="closeModal"
      v-on:removeParticipant="removeParticipant"
      v-on:startChat="startChat"
      v-on:changeParticipantPermission="changeParticipantPermission"
      />
    </b-sidebar>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ChatWindow from 'vue-advanced-chat'
import mixin from '../../utils/mixins'
import { get, getCancelable, post, getUrl, del } from '../../utils/api'
import { isAudioFile, isVideoFile, getTemporaryId } from '@/utils/util'
import axios from 'axios'

import ModalCreateChat from '../modals/ModalCreateChat.vue'
import ModalChatDetails from '../modals/ModalChatDetails.vue'
import ModalChatParticipantOptions from '../modals/ModalChatParticipantOptions.vue'
import { Howl } from 'howler'
import { nextTick } from 'vue'

const DEFAULT_MESSAGE_ACTIONS = [
  {
    name: 'replyMessage',
    title: 'Responder'
  },
  {
    name: 'sendToWhatsApp',
    title: 'Enviar mensagem para ticket'
  },
  {
    name: 'downloadFile',
    title: 'Baixar Arquivo'
  },
  {
    name: 'showMessage',
    title: 'Ver mensagem'
  },
  {
    name: 'deleteMessage',
    title: 'Apagar',
    onlyMe: true
  }
]

export default {
  name: 'InternalChat',
  components: {
    ChatWindow,
    ModalCreateChat,
    ModalChatDetails,
    ModalChatParticipantOptions
  },
  props: {
    isInternalChatActive: {
      type: Boolean,
      default: false
    }
  },
  mixins: [mixin],
  data () {
    return {
      messages: [],
      selectedRoom: null,
      rooms: [],
      loading: {
        rooms: false
      },
      loaded: {
        rooms: false
      },
      messagesLoaded: false,
      changingFilter: false,
      selectingAttendant: false,
      isDragging: false,
      roomsOrder: 'desc',
      isFullPage: false,
      breakpoint: 9999,
      loadingChat: false,
      messagesPage: 0,
      isModalCreateChatOpen: false,
      isModalChatDetailsOpen: false,
      isModalChatParticipantOptionsOpen: false,
      axiosSource: null,
      selectedUserTag: null,
      messageActions: []
    }
  },
  watch: {
    isFullPage (val) {
      if (val) {
        const messages = this.$el.querySelectorAll('.vac-player-bar')
        messages.forEach(message => {
          message.setAttribute('style', 'max-width: calc(100% - 18px) !important;')
        })
      } else {
        const messages = this.$el.querySelectorAll('.vac-player-bar')
        messages.forEach(message => {
          message.setAttribute('style', 'max-width: calc(100% - 50px) !important;')
        })
      }
    },
    messages (val) {
      if (val) {
        this.$nextTick(() => {
          const messages = this.$el.querySelectorAll('.vac-player-bar')
          messages.forEach(message => {
            if (this.isFullPage) {
              message.setAttribute('style', 'max-width: calc(100% - 18px) !important;')
            } else {
              message.setAttribute('style', 'max-width: calc(100% - 50px) !important;')
            }
          })
        })
      }
    }
  },
  created () {
    this.messageActions = DEFAULT_MESSAGE_ACTIONS
    this.fetchRooms()
    this.fetchAttendants()

    this.$socket.on('internal_chat_message', data => {
      this.messageReceived(data)
    })

    this.$socket.on('internal_chat_message_progress', data => {
      this.messageProgressChanged(data)
    })

    this.$socket.on('attendant_status_changed', data => {
      this.fetchAttendants()
      this.updateRoomsParticipants(data)
    })

    this.$socket.on('internal_chat_created', data => {
      this.insertNewRoom(data)
    })

    this.$socket.on('internal_chat_typing', data => {
      this.addTypingUser(data)
    })

    this.$socket.on('internal_chat_typing_remove', data => {
      this.removeTypingUser(data)
    })

    this.$socket.on('internal_chat_updated', async (data) => {
      const chat = await this.getRoomDetails(data.chat_id)
      if (chat) {
        this.updateRooms(chat.data, false)
      }
    })

    this.$socket.on('internal_chat_message_deleted', data => {
      this.handleMessageDeleted(data)
    })

    this.$socket.on('internal_chat_removed', data => {
      this.handleChatRemoved(data)
    })

    this.$socket.on('internal_chat_group_removed', data => {
      this.$buefy.toast.open({
        message: `Você foi removido do grupo ${data.name}!`,
        type: 'is-danger',
        duration: 5000,
        position: 'is-bottom',
        queue: false
      })

      this.fetchRooms()
    })
  },
  computed: {
    ...mapGetters(['getUserId', 'allAttendantsEnabled', 'allDepartments']),
    getRooms () {
      return this.rooms.map(room => room).sort((a, b) => {
        if (this.roomsOrder === 'desc') {
          return new Date(b.lastMessage.date) - new Date(a.lastMessage.date)
        } else {
          return new Date(a.lastMessage.date) - new Date(b.lastMessage.date)
        }
      })
    },
    getUnreadCount () {
      return this.rooms.reduce((acc, room) => {
        return acc + room.unreadCount
      }, 0)
    },
    getNotificationDetails () {
      return this.rooms.map(room => {
        return {
          title: room.roomName,
          message: room.lastMessage.content,
          timestamp: room.lastMessage.timestamp,
          date: room.lastMessage.date,
          roomId: room.roomId,
          icon: room.unreadCount > 0 ? 'bell' : 'check',
          type: room.unreadCount > 0 ? 'is-light' : 'is-success'
        }
      }).sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
    },
    canShowFooter () {
      if (this.selectedRoom) {
        const participants = this.selectedRoom.internal_chat_participants

        if (participants.length === 0) return true
        else if (!participants.find(participant => participant.attendant.id === this.getUserId).send_message_blocked) return true
        else return false
      }
      return true
    }
  },
  methods: {
    ...mapActions(['fetchAttendants']),
    insertNewRoom (chat) {
      this.updateRooms(chat, true)
    },
    messageReceived (data) {
      if (
        Notification.permission !== 'denied' ||
        Notification.permission === 'default'
      ) {
        Notification.requestPermission()
      }
      const message = this.adaptMessageToMessage(data, this.getUserId)

      if (message.internal_chat) {
        this.updateRooms(message.internal_chat)
      }

      if (message) {
        const room = this.rooms.find(room => room.roomId === message.internal_chat_id)

        if (room) {
          room.lastMessage = {
            content: message.content,
            timestamp: message.timestamp,
            date: message.created_at
          }

          if (message.temporary_id) {
            const localMessageIndex = this.messages.findIndex(m => m.temporary_id === message.temporary_id)

            if (localMessageIndex > -1) {
              this.messages = this.messages.map(m => {
                if (m.temporary_id === message.temporary_id) {
                  return message
                } else {
                  return m
                }
              }).filter(msg => msg !== undefined)
            } else {
              if (this.selectedRoom && this.selectedRoom.roomId === room.roomId) {
                this.messages = [
                  ...this.messages,
                  message
                ]
              } else {
                room.unreadCount++
              }
            }
          } else {
            if (this.selectedRoom && this.selectedRoom.roomId === room.roomId) {
              this.messages = [
                ...this.messages,
                message
              ]
            } else {
              room.unreadCount++
            }
          }
        }
      }

      const alert = this.getUserId !== message.from && this.selectedRoom?.roomId !== message.internal_chat_id

      if (alert) {
        this.$emit('notification')
        this.notificationBrowser(message)
        this.playSound()
      }
    },
    notificationBrowser (message) {
      const options = {
        body: 'Mensagem: ' + message.message,
        silent: true,
        icon: '/img/icons/android-chrome-512x512.png'
      }

      if (Notification.permission === 'granted') {
        const notification = new Notification(
          'Nova mensagem recebida',
          options
        )

        notification.onclick = e => {
          e.preventDefault()
          window.focus()
          notification.close()
        }
      }
    },
    playSound () {
      try {
        const sound = new Howl({
          src: require('../../assets/quite-impressed-565.ogg'),
          volume: 0.1
        })
        sound.play()
      } catch (error) {
        console.warn('Error playing sound', error)
      }
    },
    closeInternalChat () {
      this.$emit('closeInternalChat')
    },
    toggleDrawer () {
      this.isFullPage = !this.isFullPage
      this.breakpoint = this.isFullPage ? 900 : 9999
      this.$refs.ChatWindow.isMobile = !this.isFullPage
    },
    dropHandler (event) { },
    dragoverHandler (event) {
      if (this.selectedRoom.attendant_id) {
        this.isDragging = true
      } else {
        this.$buefy.dialog.alert({
          title: 'Erro ao Enviar Arquivo',
          message:
            'O <b>ticket</b> não foi selecionado. Selecione o <b>ticket</b> antes de enviar o(s) arquivo(s)!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      }
    },
    setLoading (isLoading) {
      this.loading.rooms = isLoading
      this.loaded.rooms = !isLoading
    },
    async fetchRooms () {
      const chatsRequest = await get('internal-chat')
      const chats = chatsRequest.data

      const promises = chats.map(async chat => {
        return this.adaptChatToRoom(chat)
      })

      this.rooms = await Promise.all(promises)

      this.setLoading(false)

      this.selectedRoom = null
      this.messages = []
    },
    async adaptChatToRoom (chat) {
      if (chat.is_group) {
        chat.tags = [{
          content: 'Grupo',
          color: 'white'
        }]
        chat.name = chat.name.length === 0 ? chat.internal_chat_participants.map(participant => participant.attendant.name).join(', ') : chat.name
      } else {
        chat.name = chat.internal_chat_participants ? chat.internal_chat_participants.find(participant => participant.attendant.id !== this.getUserId).attendant.name : chat.name ?? 'Sem nome'
      }
      const chatParticipants = chat.internal_chat_participants.map(participant => {
        return {
          _id: participant.attendant.id,
          username: participant.attendant.name,
          status: {
            state: participant.attendant.status === 'online' ? 'online' : 'offline',
            last_changed: participant.attendant.chat_connect > participant.attendant.chat_disconnect ? participant.attendant.chat_connect : participant.attendant.chat_disconnect
          }
        }
      })
      const lastMessage = chat.last_message_date ?? chat.created_at
      const adaptedChat = {
        ...chat,
        roomId: chat.id,
        roomName: chat.name,
        unreadCount: chat.unread_count ?? 0,
        lastMessage: {
          content: chat.last_message ?? '',
          timestamp: this.datesAreOnSameDay(new Date(Date.now()), new Date(lastMessage)) ? `${this.formatTime(lastMessage)}` : `${this.formatDate(lastMessage)} - ${this.formatTime(lastMessage)}`,
          date: chat.last_message_date ?? chat.created_at,
          senderId: chat.last_message_by ?? null
        },
        users: chatParticipants,
        typingUsers: [],
        attendant_id: chat.attendant_id,
        fetchMessages: true
      }
      return adaptedChat
    },
    async fetchMessages ({ room, options = {} }) {
      if (!room.fetchMessages && options.reset) {
        if (this.selectedRoom) this.selectedRoom.fetchMessages = true
      }

      if (this.loadingChat && options.reset) {
        this.axiosSource.cancel()

        const CancelToken = axios.CancelToken
        this.axiosSource = CancelToken.source()
        this.getMessages({ room, options })

        return
      } else if (!this.axiosSource) {
        const CancelToken = axios.CancelToken
        this.axiosSource = CancelToken.source()
        this.getMessages({ room, options })

        return
      }

      this.getMessages({ room, options })
    },
    async getMessages ({ room, options = {} }) {
      this.loadingChat = true

      if (options.reset) {
        this.messagesPage = 0
        this.selectedRoom = room
      } else {
        this.messagesPage++
      }

      this.messagesLoaded = false
      const s = this.rooms.find(ro => ro.roomId === room.roomId)

      if (s) {
        s.unreadCount = 0
      }

      getCancelable(
        `internal-chat/${this.selectedRoom.roomId}/messages?page=${this.messagesPage}`,
        this.axiosSource.token
      )
        .then(r => {
          if (options.reset) {
            this.messages = []
          }
          this.messages = [
            ...r.data
              .map(message =>
                this.adaptMessageToMessage(message, this.getUserId)
              )
              .reverse()
              .filter(msg => msg !== undefined),
            ...this.messages
          ]
          if (!r.data.length || r.data.length < 20) {
            this.messagesLoaded = true
          }

          this.loadingChat = false
          this.changingFilter = false
        })
        .catch(thrown => {
          if (axios.isCancel(thrown)) {
          } else {
            // handle error
          }

          this.loadingChat = false
          // this.changingFilter = false
        })
    },
    adaptMessageToMessage (newMessage, userId, bypassDuplicityVerification = false) {
      const date = newMessage.whatsapp_timestamp || newMessage.created_at || new Date()

      const status = newMessage.internal_chat_message_status || {}

      const showDeletedMessage = newMessage.from === this.getUserId

      if (!newMessage.id) {
        newMessage.id = Math.floor(Math.random() * 1000000)
        newMessage.from = userId
        newMessage.timestamp = `${this.formatDate(date)} - ${this.formatTime(date)}`
      } else {
        if (this.messages.find(msg => msg.id === newMessage.id) && !bypassDuplicityVerification) return undefined

        newMessage.timestamp = `${this.formatDate(date)} - ${this.formatTime(date)}`
      }

      if (newMessage.from === this.getUserId && status.deleted) {
        const str = newMessage.message.lastIndexOf('\n')
        if (newMessage.message.length === str + 1) {
          newMessage.message = newMessage.message.substr(0, str)
        }
        if (newMessage.message.search('\n') !== -1) {
          newMessage.message = '~Mensagem apagada:~\n~' + newMessage.message.replace(/(?:\r\n|\r|\n)/g, '~\n~') + '~'
          while (newMessage.message.search('~~') !== -1) {
            newMessage.message = newMessage.message.replace('~~', '')
          }
        } else {
          newMessage.message = '~Mensagem apagada:~\n~' + newMessage.message + '~'
        }
      }

      const ret = {
        ...newMessage,
        _id: newMessage.id,
        content: newMessage.message,
        senderId: newMessage.from,
        username: newMessage.attendant.name || null,
        saved: true,
        distributed: status.sent,
        seen: status.read,
        deleted: status.deleted && !showDeletedMessage,
        disableReactions: true
      }

      if (newMessage.file_id && newMessage.file) {
        ret.files = [{
          name: newMessage.file.originalname,
          type: newMessage.file.mimetype,
          audio: newMessage.file.mimetype.indexOf('audio/ogg') > -1,
          url: getUrl(`internal-chat/${newMessage.internal_chat_id}/messages/${newMessage.id}/file`)
        }]
      }

      if (newMessage.internal_chat_message) {
        ret.replyMessage = {
          _id: newMessage.internal_chat_message.id,
          content: newMessage.internal_chat_message.message,
          sender_id: newMessage.internal_chat_message.from
        }
      }

      return ret
    },
    async sendMessage ({ content, roomId, files, replyMessage }) {
      if (replyMessage && replyMessage.isDeleted) {
        this.$buefy.toast.open(
          'Não é possível fazer reply de uma mensagem já excluida!'
        )
        return
      }

      if (files) {
        for (let i = 0; i < files.length; i++) {
          const base64 = await this.blobToBase64(files[i].blob)
          const fileBase64 = base64.substr(base64.indexOf(',') + 1)
          let fileName = files[i].name
          if (files[i].extension) fileName = `${files[i].name}.${files[i].extension}`

          try {
            this.changingFilter = true
            const temporaryId = getTemporaryId()

            const newMessage = {
              _id: temporaryId,
              id: temporaryId,
              temporary_id: temporaryId,
              from: this.getUserId,
              timestamp: `${this.formatDate(new Date())} - ${this.formatTime(new Date())}`,
              senderId: this.getUserId,
              content,
              files: [
                {
                  name: fileName,
                  type: files[i].extension,
                  audio: true,
                  duration: 14.4,
                  url: `data:${files[i].type};base64,${fileBase64}`,
                  progress: 0
                }
              ],
              deleted: false,
              isDeleted: false,
              username: null,
              disable_actions: true,
              disable_reactions: true,
              saved: true
            }

            this.messages = [
              ...this.messages,
              newMessage
            ]

            post(`internal-chat/${roomId}/messages`, {
              message: content,
              file_base64: fileBase64,
              file_mimetype: files[i].type,
              file_name: fileName,
              reply_message_id: replyMessage?.id ?? null,
              temporary_id: temporaryId
            }, (progressEvent) => {
              const { loaded, total } = progressEvent
              const percent = Math.floor(((loaded * 100) / total) / 2)
              newMessage.files[0].progress = percent
            }).catch(err => console.log(err))
          } catch (error) {
            console.warn(error)
            this.changingFilter = false
          } finally {
            this.changingFilter = false
          }
        }
      } else {
        const temporaryId = getTemporaryId()

        post(`internal-chat/${roomId}/messages`, {
          message: content,
          reply_message_id: replyMessage?.id ?? null,
          temporary_id: temporaryId
        }, (progressEvent) => {
          const { loaded, total } = progressEvent
          const percent = Math.floor(((loaded * 100) / total))

          if (percent === 100) {
            const newMessage = {
              _id: temporaryId,
              id: temporaryId,
              temporary_id: temporaryId,
              from: this.getUserId,
              timestamp: `${this.formatDate(new Date())} - ${this.formatTime(new Date())}`,
              senderId: this.getUserId,
              content: `${content}`,
              deleted: false,
              isDeleted: false,
              username: null,
              disable_actions: true,
              disable_reactions: true,
              saved: false
            }

            this.messages = [
              ...this.messages,
              newMessage
            ]
          }
        }).catch(err => console.log(err))
      }
    },
    createChatHandler () {
      this.isModalCreateChatOpen = true
    },
    blobToBase64 (blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise(resolve => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    // eslint-disable-next-line camelcase
    messageProgressChanged ({ temporary_id, progress }) {
      // eslint-disable-next-line camelcase
      const message = this.messages.find(message => message.temporary_id === temporary_id)
      if (message && message.files) {
        message.files[0].progress = Math.floor(50 + (progress / 2))
      }
    },
    sendChat (chat) {
      this.isModalCreateChatOpen = false
      post('internal-chat', chat).then(async (response) => {
        await this.fetchRooms()
        this.$buefy.toast.open({
          message: 'Chat criado com sucesso!',
          type: 'is-success',
          duration: 5000,
          position: 'is-bottom',
          queue: false
        })
        this.selectedRoom = this.rooms.find(room => room.roomId === response.data.id)
      }).catch(err => {
        this.$buefy.dialog.alert({
          title: 'Erro ao criar chat',
          message: err.response.data.message || 'Erro ao criar chat',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })
    },
    async handleAddParticipant (attendant) {
      await post(`internal-chat/${this.selectedRoom.roomId}/participants`, {
        attendant_id: attendant.id,
        is_admin: false,
        send_message_blocked: false
      }).catch((err) => {
        this.$buefy.dialog.alert({
          title: 'Erro ao adicionar participante',
          message: err.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })

      const chat = await this.getRoomDetails(this.selectedRoom.roomId)
      await this.updateRooms(chat.data, false)
    },
    async exitGroup () {
      await del(`internal-chat/${this.selectedRoom.roomId}/participants/${this.getUserId}`).catch((err) => {
        this.$buefy.dialog.alert({
          title: 'Erro ao sair do grupo',
          message: err.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })

      this.isModalChatDetailsOpen = false
      this.fetchRooms()
    },
    async removeParticipant (attendantId) {
      await del(`internal-chat/${this.selectedRoom.roomId}/participants/${attendantId}`).catch((err) => {
        this.$buefy.dialog.alert({
          title: 'Erro ao remover participante',
          message: err.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })

      const chat = await this.getRoomDetails(this.selectedRoom.roomId)
      await this.updateRooms(chat.data, false)
    },
    async updateRooms (room, notification = true) {
      const r = this.rooms.find(ro => ro.roomId === room.id)

      if (!r) {
        const newRoom = await this.adaptChatToRoom(room)

        if (this.selectedRoom && this.selectedRoom.id !== newRoom.id) {
          this.selectedRoom.fetchMessages = false
        }

        this.$set(this.rooms, this.rooms.length, newRoom)

        if (notification) {
          this.$buefy.toast.open(`${newRoom.roomName} abriu uma conversa com você!`)
        }
      } else {
        const lastMessage = room.last_message_date || room.created_at

        r.last_message_date = lastMessage
        r.lastMessage.timestamp = this.datesAreOnSameDay(new Date(Date.now()), new Date(r.last_message_date)) ? `${this.formatTime(r.last_message_date)}` : `${this.formatDate(lastMessage)} - ${this.formatTime(lastMessage)}`
        r.lastMessage.content = room.last_message ?? ''
        if (!this.selectedRoom || r.roomId !== this.selectedRoom.roomId) {
          r.unreadCount = room.unread_count
        }

        this.updateChatObject(r, room)
      }
    },
    updateChatObject (chat, newChat) {
      chat.attendant_id = newChat.attendant_id
      chat.attendant = newChat.attendant
      chat.internal_chat_participants = newChat.internal_chat_participants
      chat.last_message = newChat.last_message ?? ''
      chat.last_message_by = newChat.last_message_by
      chat.last_message_date = newChat.last_message_date ?? newChat.created_at
    },
    async getRoomDetails (roomId) {
      return await get(`internal-chat/${roomId}`)
    },
    async deleteChat (chatId) {
      await del(`internal-chat/${chatId}`).catch((err) => {
        this.$buefy.dialog.alert({
          title: 'Erro ao excluir chat',
          message: err.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      })

      this.isModalChatDetailsOpen = false
      this.fetchRooms()
    },
    async handleOpenUserTag ({ user }) {
      if (user._id === this.getUserId) return
      if (!this.selectedRoom.is_group) return
      this.selectingAttendant = true
      const chat = await this.getRoomDetails(this.selectedRoom.roomId).then(r => r.data)
      const participant = chat.internal_chat_participants.find(participant => participant.attendant.id === user._id)
      this.selectingAttendant = false
      this.selectedUserTag = participant
      this.isModalChatParticipantOptionsOpen = true
    },
    closeModal () {
      this.isModalChatParticipantOptionsOpen = false
    },
    async handleTypingMessage ({ roomId }) {
      const r = this.rooms.find(room => room.roomId === roomId)
      if (r.typingUsers.includes(this.getUserId)) return
      this.$socket.emit('internal_chat_typing', {
        internal_chat_id: roomId,
        attendant_id: this.getUserId
      })
    },
    // eslint-disable-next-line camelcase
    addTypingUser ({ internal_chat_id, attendant_id }) {
      // eslint-disable-next-line camelcase
      const room = this.rooms.find(room => room.roomId === internal_chat_id)

      if (room && !room.typingUsers.includes(attendant_id)) {
        room.typingUsers.push(attendant_id)
      }
    },
    // eslint-disable-next-line camelcase
    removeTypingUser ({ internal_chat_id, attendant_id }) {
      // eslint-disable-next-line camelcase
      const room = this.rooms.find(room => room.roomId === internal_chat_id)

      if (room.typingUsers.includes(attendant_id)) {
        // eslint-disable-next-line camelcase
        room.typingUsers = room.typingUsers.filter(user => user !== attendant_id)
      }
    },
    // eslint-disable-next-line camelcase
    updateRoomsParticipants ({ status, attendant_id }) {
      this.rooms = this.rooms.map(room => {
        // eslint-disable-next-line camelcase
        if (room.users.find(user => user._id === attendant_id)) {
          room.users = room.users.map(user => {
            // eslint-disable-next-line camelcase
            if (user._id === attendant_id) {
              return {
                ...user,
                status: {
                  state: status === 'online' ? 'online' : 'offline',
                  last_changed: Date.now()
                }
              }
            } else {
              return user
            }
          })
        }

        return room
      })
    },
    selectRoom (roomId) {
      const room = this.rooms.find(room => room.roomId === roomId)
      this.selectedRoom = room
      this.messages = []
      this.fetchMessages({ room, options: { reset: true } })
    },
    startChat (participant) {
      const room = this.rooms.find(room => room.internal_chat_participants.find(pt => pt.attendant.id === participant.attendant.id) && !room.is_group)
      if (room) {
        this.selectRoom(room.roomId)
      } else {
        this.sendChat({
          name: participant.attendant.name + ' - ' + this.getName,
          description: '',
          is_group: false,
          attendants: [
            {
              id: participant.attendant.id,
              is_admin: false,
              send_message_blocked: false
            },
            {
              id: this.getUserId,
              is_admin: true,
              send_message_blocked: false
            }
          ]
        })
      }
    },
    async changeParticipantPermission (participant) {
      const room = this.rooms.find(room => room.roomId === this.selectedRoom.roomId)

      if (!room) return
      if (!room.is_group) return

      const isAdmin = room.internal_chat_participants.find(pt => pt.attendant.id === this.getUserId).is_admin

      if (!isAdmin) {
        this.$buefy.dialog.alert({
          title: 'Erro ao alterar permissão',
          message: 'Você não tem permissão para alterar a permissão de outros participantes!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
        return
      }

      try {
        this.selectingAttendant = true
        await post(`internal-chat/${room.id}/participants/${participant.attendant.id}`, {
          is_admin: participant.is_admin,
          send_message_blocked: participant.send_message_blocked
        }).then(async () => {
          const chat = await this.getRoomDetails(this.selectedRoom.roomId)
          await this.updateRooms(chat.data, false)
        })
      } catch (error) {
        console.log(error)
        this.$buefy.dialog.alert({
          title: 'Erro ao alterar permissão',
          message: error.response.data.message,
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })
      } finally {
        this.selectingAttendant = false
      }
    },
    async messageActionHandler ({ roomId, action, message }) {
      switch (action.name) {
        case 'downloadFile':
          this.downloadFile(message)
          break
        case 'sendToWhatsApp':
          this.$emit('sendToWhatsApp', message)
          break
        case 'showMessage':
          if (message.internal_chat_message) {
            try {
              this.getMessagesFromSearch(message.internal_chat_message.id)
            } catch (error) {
              this.$buefy.toast.open('Mensagem não encontrada')
            }
          } else {
            this.$buefy.toast.open('Não há reply nesta mensagem')
          }
          break
      }
    },
    async getMessagesFromSearch (msgId) {
      if (this.messages.filter(el => el.id === msgId).length > 0) {
        this.scrollToMessage(msgId)
      } else {
        try {
          this.messagesLoaded = false
          this.loadingChat = true

          const msg = await get(`internal-chat/${this.selectedRoom.roomId}/messages?page=${this.messagesPage++}`, { msgId })
          if (msg.data.filter(el => el.id === msgId).length > 0) {
            this.messages = [
              ...msg.data
                .reverse()
                .map(message => this.adaptMessageToMessage(message, this.getUserId))
                .filter(msg => msg !== undefined),
              ...this.messages
            ]

            this.messagesPage = Math.floor(this.messages.length / 15)
          }
        } catch (error) {
          console.log('error', error)
        } finally {
          this.loadingChat = false
          this.changingFilter = false

          nextTick(() => this.scrollToMessage(msgId))
        }
      }
    },
    scrollToMessage (msgId) {
      const element = document.getElementById(msgId)
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' })
        element.classList.add('highlight-message')
        setTimeout(() => {
          element.classList.remove('highlight-message')
        }, 1000)
      }
    },
    downloadFile (message) {
      if (!message.files) {
        this.$buefy.toast.open(
          'Não é possível baixar arquivo de mensagem sem arquivo! Faça o download primeiro do arquivo.'
        )
      } else {
        if (isVideoFile(message.files[0].type)) {
          this.$buefy.toast.open(
            'Utilize a opção de download dentro do player de vídeo.'
          )
          return
        }
        const a = document.createElement('a')
        a.href = message.files[0].url
        let ext
        try {
          if (isAudioFile(message.files[0].type)) {
            ext = '.mp3'
          } else {
            ext = message.files[0].type.split('/')[1]
          }
        } catch (error) {
          ext = ''
        }
        a.download = `${message.files[0].name ?? 'download.' + ext}`
        a.click()
      }
    },
    deleteMessage ({ roomId, message }) {
      if (message.deleted) {
        this.$buefy.toast.open(
          'Não é possível excluir uma mensagem já excluida!'
        )
        return
      }

      if (message.from !== this.getUserId) return

      const now = new Date()
      const sentDate = new Date(message.created_at)
      const limit = sentDate.setMinutes(sentDate.getMinutes() + 30)

      if (sentDate && now >= limit) {
        this.$buefy.toast.open(
          'Não é possível apagar essa mensagem'
        )
        return
      }

      this.$buefy.dialog.confirm({
        title: 'Excluir mensagem',
        message: 'Tem certeza que deseja excluir essa mensagem?',
        confirmText: 'Excluir',
        cancelText: 'Cancelar',
        type: 'is-danger',
        hasIcon: true,
        icon: 'times-circle',
        iconPack: 'fa',
        onConfirm: async () => {
          await del(`internal-chat/${roomId}/messages/${message.id}`).catch((err) => {
            this.$buefy.dialog.alert({
              title: 'Erro ao excluir mensagem',
              message: err.response.data.message,
              type: 'is-danger',
              hasIcon: true,
              icon: 'times-circle',
              iconPack: 'fa',
              ariaRole: 'alertdialog',
              ariaModal: true
            })
          })

          const chat = await this.getRoomDetails(this.selectedRoom.roomId)
          await this.updateRooms(chat.data, false)
        }
      })
    },
    async handleMessageDeleted (message) {
      if (this.selectedRoom && this.selectedRoom.roomId === message.internal_chat_id) {
        const msg = this.messages.find(msg => msg.id === message.id)
        if (msg) {
          if (msg.from === this.getUserId) {
            const str = msg.content.lastIndexOf('\n')
            if (msg.content.length === str + 1) {
              msg.content = msg.content.substr(0, str)
            }
            if (msg.content.search('\n') !== -1) {
              msg.content = '~Mensagem apagada:~\n~' + msg.content.replace(/(?:\r\n|\r|\n)/g, '~\n~') + '~'
              while (msg.content.search('~~') !== -1) {
                msg.content = msg.content.replace('~~', '')
              }
            } else {
              msg.content = '~Mensagem apagada:~\n~' + msg.content + '~'
            }
          } else {
            msg.isDeleted = true
            msg.deleted = true
          }
        }

        const chat = await this.getRoomDetails(this.selectedRoom.roomId)
        await this.updateRooms(chat.data, false)
      }
    },
    handleChatRemoved (data) {
      if (this.selectedRoom && this.selectedRoom.roomId === data.internal_chat_id) {
        this.$buefy.toast.open(
          'Este chat foi excluído pelo atendente!'
        )
        this.fetchRooms()
      } else {
        this.$buefy.toast.open(
          'Um chat foi excluído!'
        )
        this.fetchRooms()
      }
    }
  }
}
</script>
<style scoped>
.highlight-message {
  background-color: #ffcc00;
  border-radius: 10px;
  transition: background-color 0.5s ease;
}
</style>
