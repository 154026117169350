<template>
  <div>
    <b-modal
      :active="isComponentModalActive"
      @close="closeModal"
      has-modal-card
    >
      <div class="modal-card">
        <header class="modal-card-head">
        </header>
        <section class="modal-card-body">
          <b-steps
            v-model="activeStep"
            :animated="true"
            :has-navigation="false"
            :type="'is-primary'"
            :size="'is-small'"
            :rounded="true"
            :aria-role="'navigation'"
            :aria-label="'steps'"
          >
            <b-step-item
              :label="'Começar nova conversa'"
              :type="'is-primary'"
              :icon="'account-multiple'"
              step="1"
            >
              <div>
                <p class="has-text-centered mb-4">Você deseja iniciar:</p>
                <div class="columns mt-4 is-vcentered is-centered">
                  <div class="column is-half">
                    <div class="has-text-centered">
                      <b-button class="is-primary" size="is-large" icon-left="message-plus" @click="handleSteps(1, false)">
                        Nova conversa
                      </b-button>
                    </div>
                  </div>
                  <div style="border-left:1px solid #dcdcdc;height:500px; margin-right: 0.5em;"></div>
                  <div class="column is-half">
                    <div class="has-text-centered">
                      <b-button class="is-primary" size="is-large" icon-left="account-multiple-plus" @click="handleSteps(1, true)"> Novo grupo </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </b-step-item>
            <b-step-item
              :label="isGroup ? 'Selecione os atendentes' : 'Selecione o atendente'"
              :type="'is-primary'"
              :icon="'account-multiple'"
              step="2"
            >
              <div>
                <b-field label="Filtros" grouped>
                  <b-select v-model="filter" label="Filtrar por">
                    <option value="name">Nome</option>
                    <option value="department">Departamento</option>
                    <option value="status">Status</option>
                  </b-select>
                  <b-autocomplete
                    v-if="filter === 'name'"
                    v-model="filterAtt"
                    placeholder="Digite o nome do atendente"
                    :keep-first="false"
                    :open-on-focus="true"
                    :data="getAttendants"
                    field="name"
                    :clearable="true"
                    expanded
                  />
                  <b-select v-if="filter === 'department'" v-model="filterDepartment" label="Departamento" expanded>
                    <option value="all">Todos</option>
                    <option v-for="department in getDepartments" :key="department.id" :value="department.id">{{ department.name }}</option>
                  </b-select>
                  <b-select v-if="filter === 'status'" v-model="filterStatus" expanded>
                    <option value="all">Todos</option>
                    <option value="online">Online</option>
                    <option value="offline">Offline</option>
                    <option value="invisible">Ausente</option>
                  </b-select>
                </b-field>
                <b-table
                :data="getAttendantsFiltered"
                :paginated="true"
                :per-page="5"
                :pagination-simple="true"
                :pagination-position="'bottom'"
                :pagination-text="'Atendentes'"
                :mobile-cards="true"
                :bordered="true"
                :checked-rows.sync="attendantsSelected"
                :is-row-checkable="validateRow"
                :header-checkable="isGroup"
                checkable
                hoverable
                scrollable
                >
                  <b-table-column field="name" sortable label="Atendente" v-slot="props">
                    <b-icon icon="circle" size="is-small" v-if="props.row.status === 'online'" style="color: green;"></b-icon>
                    <b-icon icon="circle" size="is-small" v-else-if="props.row.status === 'invisible'" style="color: orange;"></b-icon>
                    <b-icon icon="circle" size="is-small" v-else style="color: red;"></b-icon>
                    {{ props.row.name }}
                  </b-table-column>
                </b-table>
                <b-collapse
                class="card"
                animation="slide"
                :open.sync="collapseOpen"
                aria-id="contentIdForA11y3"
                >
                  <template #trigger="props">
                    <div
                        class="card-header"
                        role="button"
                        aria-controls="contentIdForA11y3"
                        :aria-expanded="props.open">
                        <p class="card-header-title">
                            {{ isGroup ? 'Atendentes selecionados' : 'Atendente selecionado' }}
                        </p>
                        <a class="card-header-icon">
                            <b-icon
                                :icon="props.open ? 'menu-up' : 'menu-down'">
                            </b-icon>
                        </a>
                    </div>
                  </template>
                  <div class="card-content">
                      <div class="content">
                        <b-field grouped group-multiline>
                          <div class="control" v-for="attendant in attendantsSelected" :key="attendant.id">
                            <b-tag
                              type="is-primary"
                              size="is-medium"
                              class="mr-2"
                              closable
                              attached
                              close-type='is-danger-outlined'
                              @close="attendantsSelected = attendantsSelected.filter(a => a.id !== attendant.id)"
                            >
                              {{ attendant.name }}
                            </b-tag>
                          </div>
                        </b-field>
                      </div>
                  </div>
                </b-collapse>
              </div>
            </b-step-item>
            <b-step-item
              :label="'Opções'"
              :type="'is-primary'"
              :icon="'account-multiple'"
              :visible="isGroup"
              step="3"
            >
              <div class="my-4">
                <b-field label="Dê um nome ao grupo">
                  <b-input
                    v-model="name"
                    placeholder="Digite o nome do grupo"
                    :expanded="true"
                    :required="true"
                  />
                </b-field>
                <br>
                <b-field label="Dê uma descrição ao grupo (opcional)">
                  <b-input
                    v-model="description"
                    type="textarea"
                    placeholder="Digite a descrição do grupo"
                    :expanded="true"
                  />
                </b-field>
                <b-field label="Selecione os usuários que serão administradores do grupo">
                  <multiselect
                  v-model="attendantsAdmin"
                  :multiple="true"
                  :options="attendantsToAdmin"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione um dos atendentes"
                  label="name"
                  track-by="name"
                  :preselect-first="true"
                  :hide-selected="true"
                  />
                </b-field>

                <b-field label="Selecione os usuário que não poderão enviar mensagens no grupo">
                  <multiselect
                  v-model="attendantsBlocked"
                  :multiple="true"
                  :options="attendantsMessageBlocked"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  placeholder="Selecione um dos atendentes"
                  label="name"
                  track-by="name"
                  :preselect-first="true"
                  :hide-selected="true"
                  />
                </b-field>
              </div>
            </b-step-item>
            <template
                v-if="(activeStep > 0 && activeStep < 3) && isGroup"
                #navigation="{previous, next}">
                <div class="step-buttons">
                  <b-button
                    outlined
                    type="is-danger"
                    icon-left="arrow-left-bold"
                    :disabled="previous.disabled"
                    @click.prevent="previous.action">
                    Voltar
                  </b-button>
                  <b-button
                      outlined
                      type="is-success"
                      icon-right="arrow-right-bold"
                      :disabled="next.disabled || attendantsSelected.length === 0"
                      @click.prevent="next.action">
                    Avançar
                  </b-button>
                </div>
            </template>
          </b-steps>
        </section>
        <footer class="modal-card-foot">
          <button class="button" type="button" @click="closeModal">
            Fechar
          </button>
          <button class="button is-primary" :disabled="disableConfirm" @click="sendChat">
            Selecionar
          </button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import multiselect from 'vue-multiselect'
export default {
  name: 'ModalCreateChat',
  components: {
    multiselect
  },
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    isCreatingNewChat: {
      type: Boolean,
      required: true
    },
    attendants: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      attendantsSelected: [],
      departmentSelected: null,
      isFetching: false,
      name: '',
      description: '',
      isChat: false,
      isGroup: false,
      activeStep: 0,
      filter: '',
      filterStatus: 'all',
      filterDepartment: 'all',
      filterAtt: '',
      lastStep: false,
      attendantsAdmin: [],
      attendantsBlocked: [],
      collapseOpen: false
    }
  },
  computed: {
    ...mapGetters(['getName', 'getUserId']),
    getDepartments () {
      return this.departments.filter(d => d.enabled)
    },
    getAttendants () {
      return this.attendants.filter(a => a.id !== this.getUserId)
    },
    getAttendantsFiltered () {
      const attendants = this.attendants.filter(a => a.id !== this.getUserId)

      switch (this.filter) {
        case 'name':
          return attendants.filter(a => a.name.toLowerCase().includes(this.filterAtt.toLowerCase()))
        case 'department':
          if (this.filterDepartment === 'all') {
            return attendants
          } else {
            return attendants.filter(a => a.departments.some(d => d.id === this.filterDepartment))
          }
        case 'status':
          if (this.filterStatus === 'all') return attendants
          return attendants.filter(a => a.status === this.filterStatus)
        default:
          return attendants
      }
    },
    disableConfirm () {
      if (this.isChat) {
        return this.attendantsSelected.length === 0
      } else if (this.isGroup) {
        return this.attendantsSelected.length === 0 || this.name === ''
      } else {
        return true
      }
    },
    attendantsToAdmin () {
      return this.attendantsSelected.filter(a => !this.attendantsBlocked.includes(a))
    },
    attendantsMessageBlocked () {
      return this.attendantsSelected.filter(a => !this.attendantsAdmin.includes(a))
    }
  },
  watch: {
    activeStep (val) {
      if (val < 1) {
        this.attendantsSelected = []
        this.name = ''
        this.description = ''
        this.isGroup = false
        this.isChat = false
        this.collapseOpen = false
        this.attendantsAdmin = []
        this.attendantsBlocked = []
        this.filter = ''
      }
    },
    attendantsSelected (val) {
      if (!this.isGroup && val.length > 0) {
        this.name = val[0].name + ' - ' + this.getName
      }
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    sendChat () {
      const attendantsToSend = this.attendantsSelected.map(a => {
        return {
          id: a.id,
          is_admin: this.attendantsAdmin.some(adm => adm.id === a.id),
          send_message_blocked: this.attendantsBlocked.some(b => b.id === a.id)
        }
      })
      attendantsToSend.push({
        id: this.getUserId,
        is_admin: true,
        send_message_blocked: false
      })
      this.$emit('sendChat', {
        name: this.name,
        description: this.description,
        is_group: this.isGroup,
        attendants: attendantsToSend
      })
    },
    validateRow (row) {
      if (this.attendantsSelected.length > 0 && !this.isGroup) {
        return this.attendantsSelected.some(a => a.id === row.id)
      } else if (this.isGroup) {
        return true
      } else if (this.isChat) {
        return this.attendantsSelected.length === 0
      }
    },
    handleSteps (step, isGroup) {
      switch (step) {
        case 1:
          this.isChat = !isGroup
          this.isGroup = isGroup
          this.activeStep = 1
          break
        case 2:
          if (this.isChat) {
            this.isGroup = false
          } else if (this.isGroup) {
            this.isGroup = true
          }
          break
        case 3:
          break
      }
    }
  }
}
</script>
<style scoped>
.step-buttons {
  display: flex !important;
  justify-content: space-between !important;
  margin-top: 1em !important;
}
</style>
