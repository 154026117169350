<template>
    <div>
        <b-modal
        :active="isComponentModalActive"
        @close="closeModal"
        has-modal-card
        >
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Detalhes</p>
                </header>
                <section class="modal-card-body">
                    <b-field :label="chat.is_group ? 'Nome do grupo' : 'Nome da conversa'">
                        <b-input
                        :value="chat.name"
                        :readonly="true"
                        ></b-input>
                    </b-field>
                    <b-field v-if="chat.description" label="Descrição">
                        <b-input
                        :value="chat.description"
                        type="textarea"
                        :readonly="true"
                        ></b-input>
                    </b-field>
                    <b-field v-if="chat.is_group" label="Participantes">
                        <b-button type="is-success" icon-left="plus" @click="openModalAddParticipant" expanded>
                            Adicionar participante
                        </b-button>
                    </b-field>
                    <b-field v-if="chat.is_group">
                        <b-table
                        :data="getParticipants"
                        :paginated="true"
                        :per-page="5"
                        :pagination-simple="true"
                        :mobile-cards="true"
                        :bordered="false"
                        :selected.sync="participantSelected"
                        @select="openModalParticipantOptions"
                        @mouseenter="mouseenter"
                        @mouseleave="mouseleave"
                        hoverable
                        scrollable
                        focusable
                        >
                            <b-table-column field="name" label="Nome" v-slot="props">
                                <b-icon icon="circle" size="is-small" v-if="props.row.attendant.status === 'online'" style="color: green;"></b-icon>
                                <b-icon icon="circle" size="is-small" v-else-if="props.row.attendant.status === 'invisible'" style="color: orange;"></b-icon>
                                <b-icon icon="circle" size="is-small" v-else style="color: red;"></b-icon>
                                {{ props.row.attendant.name }}
                            </b-table-column>
                            <b-table-column field="is_admin" label="É administrador?" v-slot="props">
                                <b-icon icon="check" size="is-small" v-if="props.row.is_admin" style="color: green;"></b-icon>
                                <b-icon icon="close" size="is-small" v-else style="color: red;"></b-icon>
                            </b-table-column>
                            <b-table-column field="is_admin" label="Pode enviar mensagens no grupo?" v-slot="props">
                                <b-icon icon="close" size="is-small" v-if="props.row.send_message_blocked" style="color: red;"></b-icon>
                                <b-icon icon="check" size="is-small" v-else style="color: green;"></b-icon>
                            </b-table-column>
                        </b-table>
                    </b-field>
                    <b-field v-if="chat.is_group">
                        <b-button type="is-danger" icon-left="exit-run" @click="exitGroup" expanded>
                            Sair do grupo
                        </b-button>
                    </b-field>
                    <b-field v-if="chat.is_group">
                        <b-button v-if="getParticipantIsAdmin" :disabled="!getParticipantIsAdmin" type="is-danger" icon-left="delete-forever" @click="deleteChat(chat.id)" expanded>
                            Excluir grupo
                        </b-button>
                    </b-field>
                    <b-field v-else>
                        <b-button type="is-danger" icon-left="close" @click="deleteChat(chat.id)" expanded>
                            Fechar conversa
                        </b-button>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="closeModal">
                        Fechar
                    </button>
                </footer>
            </div>
        </b-modal>
        <ModalChatParticipantOptions
        v-if="isComponentModalActiveParticipantOptions"
        :participant="participantSelected"
        :isComponentModalActive="isComponentModalActiveParticipantOptions"
        @closeModal="closeModalParticipantOptions"
        v-on:removeParticipant="removeParticipant"
        v-on:startChat="startChat"
        v-on:changeParticipantPermission="changeParticipantPermission"
        />
        <ModalChatAddParticipant
        v-if="isComponentModalActiveAddParticipant"
        :chat="chat"
        :isComponentModalActive="isComponentModalActiveAddParticipant"
        @closeModal="isComponentModalActiveAddParticipant = false"
        v-on:addParticipant="addParticipant"
        />
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ModalChatParticipantOptions from './ModalChatParticipantOptions.vue'
import ModalChatAddParticipant from './ModalChatAddParticipant.vue'
export default {
  name: 'ModalChatDetails',
  components: {
    ModalChatParticipantOptions,
    ModalChatAddParticipant
  },
  props: {
    chat: {
      type: Object,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      participantSelected: null,
      isComponentModalActiveParticipantOptions: false,
      isComponentModalActiveAddParticipant: false
    }
  },
  computed: {
    ...mapGetters(['getUserId']),
    getParticipants () {
      return this.chat.internal_chat_participants.map(participant => {
        return participant
      })
    },
    getParticipantIsAdmin () {
      return this.chat.internal_chat_participants.find(participant => {
        return participant.attendant.id === this.getUserId
      }).is_admin
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    removeParticipant (participantId) {
      if (!this.getParticipantIsAdmin && this.getUserId !== participantId) {
        this.$buefy.toast.open({
          message: 'Você não tem permissão para remover participantes',
          type: 'is-danger'
        })
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Remover participante',
        message: 'Tem certeza que deseja remover este participante?',
        confirmText: 'Remover',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('removeParticipant', participantId)
        }
      })
    },
    deleteChat (chatId) {
      if (!this.getParticipantIsAdmin && this.chat.is_group) {
        this.$buefy.toast.open({
          message: 'Você não tem permissão para excluir',
          type: 'is-danger'
        })
        return
      }
      this.$buefy.dialog.confirm({
        title: 'Excluir grupo',
        message: 'Tem certeza que deseja excluir?',
        confirmText: 'Excluir',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('deleteChat', chatId)
        }
      })
    },
    openModalParticipantOptions (participant) {
      this.participantSelected = participant
      this.isComponentModalActiveParticipantOptions = true
    },
    closeModalParticipantOptions () {
      this.isComponentModalActiveParticipantOptions = false
      this.participantSelected = null
    },
    mouseenter (payload, event) {
      event.target.closest('tr').classList.add('is-selected')
      event.target.closest('tr').style.cursor = 'pointer'
    },
    mouseleave (payload, event) {
      event.target.closest('tr').classList.remove('is-selected')
      event.target.closest('tr').style.cursor = 'default'
    },
    openModalAddParticipant () {
      this.isComponentModalActiveAddParticipant = true
    },
    addParticipant (attendant) {
      this.$emit('addParticipant', attendant)
      this.isComponentModalActiveAddParticipant = false
    },
    exitGroup () {
      this.$buefy.dialog.confirm({
        title: 'Sair do grupo',
        message: 'Tem certeza que deseja sair deste grupo?',
        confirmText: 'Sair',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.$emit('exitGroup', this.chat.id)
        }
      })
    },
    startChat (participant) {
      this.$emit('startChat', participant)
      this.closeModal()
    },
    changeParticipantPermission (participant) {
      this.$emit('changeParticipantPermission', participant)
    }
  }
}
</script>
