<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" width="30%">
        <div class="modal-card delete-message" style="width: auto">
          <header class="modal-card-head delete-message-head">
            <p class="modal-card-title delete-message-title">
              Deseja apagar a mensagem?
            </p>
          </header>
          <section class="modal-card-body delete-message-body">
            <button class="button" type="button" @click="deleteMessage(true)">
              Apagar para todos
            </button>
            <br>
            <button class="button" type="button" @click="closeModal">
              Cancelar
            </button>
          </section>
          <footer class="modal-card-foot delete-message-foot">
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
export default {
  name: 'ModalDeleteMessage',
  props: {
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    message: {
      type: Number,
      required: true
    }
  },
  methods: {
    deleteMessage (everyone) {
      const data = {
        messageId: this.message,
        forEveryone: everyone
      }
      this.$emit('deleteMessage', data)
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.delete-message {
  padding: 22px 24px 20px;
}

.delete-message .delete-message-head {
  text-align: left;
  border-radius: 6px 6px 0 0;
  display: inline;
  border: 0px !important;
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #ffffff;
}

.delete-message .delete-message-title {
  font-size: 16px;
}

.delete-message .delete-message-body {
  padding-top: 10px;
  max-height: 40%;
  text-align: right;
}

.delete-message .delete-message-foot {
  padding: 3px;
}

.delete-message .delete-message-body button {
  color: #208256;
  margin: 6px 0px;
  font-size: 16px;
}

.delete-message .delete-message-body button:hover {
  border: 1px solid #229b64a7;
}

</style>
