<template>
    <div>
        <b-modal
        :active="isComponentModalActive"
        @close="closeModal"
        style="width: auto;"
        has-modal-card>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">Adicionar Participante</p>
                </header>
                <section class="modal-card-body overflow-y-hidden">
                    <b-field label="Digite o nome de um atendente">
                        <b-autocomplete
                        :data="getAttendants"
                        field="name"
                        clearable
                        placeholder="Digite o nome de um atendente"
                        append-to-body
                        @select="selectAttendant"
                        >
                            <template slot="empty">Nenhum atendente encontrado</template>
                        </b-autocomplete>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <button class="button" type="button" @click="closeModal">
                        Fechar
                    </button>
                </footer>
            </div>
        </b-modal>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    name: "ModalChatAddParticipant",
    props: {
        chat: {
            type: Object,
            required: true
        },
        isComponentModalActive: {
            type: Boolean,
            required: true
        }
    },
    created () {
        this.fetchAttendants()
    },
    computed: {
        ...mapGetters(['getUserId', 'allAttendantsEnabled']),
        getAttendants () {
            return this.allAttendantsEnabled.filter(attendant => {
                return !this.chat.internal_chat_participants.find(participant => {
                    return participant.attendant_id === attendant.id
                })
            })
        }
    },
    methods: {
        ...mapActions(['fetchAttendants']),
        closeModal() {
            this.$emit('closeModal')
        },
        selectAttendant (attendant) {
            this.$buefy.dialog.confirm({
                title: 'Adicionar Participante',
                message: `Deseja adicionar <b>${attendant.name}</b> como participante?`,
                confirmText: 'Sim',
                cancelText: 'Não',
                type: 'is-primary',
                hasIcon: true,
                onConfirm: () => {
                    this.$emit('addParticipant', attendant)
                }
            })
        },
    }
}
</script>