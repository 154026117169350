<template>
  <div>
    <div class="search-message-div" ref="searchMessagesDiv">
      <div class="search-message-head">
        <div>
          <span>Pesquisar mensagens...</span>
          <b-button icon-right="close" v-on:click="closeMessageSearch" />
        </div>
      </div>
      <div class="search-message-input">
        <b-field size="is-small">
          <b-input
            rounded
            v-model="message"
            placeholder="Pesquisar..."
            icon="magnify"
            icon-clickable
            @icon-click="handleSearchMessage"
            @keydown.enter.native="handleSearchMessage"
          />
        </b-field>
      </div>
    </div>
    <div class="found-messages" :style="{ height: foundMessagesListHeight }" >
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="true" />
      <div v-if="!noMessage">
        <div v-for="msg in foundMessages" :key="msg.id">
          <FoundMessage :message="msg" @scrollToMessage="scrollToMessage(msg.id)" />
        </div>
      </div>
      <span class="no-message" v-else>Nenhuma mensagem encontrada</span>
    </div>
  </div>
</template>

<script>
import FoundMessage from './FoundMessage.vue'

export default {
  name: 'SearchRoomMessages',
  props: {
    foundMessages: {
      type: Array,
      required: false
    },
    isSearching: {
      type: Boolean,
      default: false
    }
  },
  components: {
    FoundMessage
  },
  data () {
    return {
      message: '',
      isLoading: false,
      foundMessagesListHeight: 'calc(100vh - 125px)',
      noMessage: false
    }
  },
  methods: {
    closeMessageSearch () {
      this.$emit('closeMessageSearch')
      this.message = ''
    },
    handleSearchMessage () {
      if (this.message && this.message !== '') {
        this.$emit('handleSearchMessage', this.message)
      }
    },
    scrollToMessage (msgId) {
      this.$emit('scrollToMessage', msgId)
    }
  },
  updated () {
    this.foundMessagesListHeight = `calc(100vh - 125px - ${this.$refs.searchMessagesDiv.clientHeight}px)`
  },
  watch: {
    isSearching (newValue, oldValue) {
      this.isLoading = newValue
      if (!newValue && oldValue && this.foundMessages.length < 1) {
        this.noMessage = true
      } else {
        this.noMessage = false
      }
    }
  }
}
</script>

<style lang="scss">
.search-message-div {
  border-bottom: 1px solid #e1e4e8;
  box-shadow: 0px 1px 5px #e1e4e8;
}

.search-message-head {
  border-bottom: 1px solid #e1e4e8;
  height: 64px;
  display: flex;
  align-items: center;
  background-color: #FFFFFF;
}

.search-message-head div {
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding: 0em 1em;
}

.search-message-head div span:first-child {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  color: var(--chat-header-color-name);
}

.search-message-head div button {
  margin-left: auto;
  display: flex;
  align-items: center;
  color: #8f8f8f;
  cursor: pointer;
  border: 0px;
  .icon {
    font-size: 20px
  }
}

@media only screen and (max-width: 768px) {
  .search-message-head {
    height: 50px;
  }
}

.search-message-input {
  padding: 0.5em
}

.found-messages {
  overflow: auto;
  position: relative;
}

.found-message {
  padding: 1em 0.5em;
  border-bottom: 1px solid #e1e4e8;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}

.found-message:hover {
  background-color: #eeeeeece;
  box-shadow: 0px 1px 5px #e1e4e8;
}

.found-message .message-timestamp {
  font-size: 12px;
  padding-bottom: 2px;
}

.no-message {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  padding: 15px 5px;
  text-align: center;
}
</style>
