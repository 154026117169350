<template>
<div @click="scrollToMessage">
  <div v-if="message.from.length < 36" class="found-message">
    <div class="message-timestamp">
      {{ message.timestamp }}
    </div>
    <div>
      {{ message.whatsapp_sender_name ?? message.from }}: <span v-html="getMessage" />
    </div>
  </div>
  <div v-else class="found-message">
    <div class="message-timestamp">
      {{ message.timestamp }}
    </div>
    <div>
      Você: <span v-html="getMessage" />
    </div>
  </div>
</div>
</template>

<script>

export default {
  name: 'FoundMessage',
  props: {
    message: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      //
    }
  },
  computed: {
    getMessage: {
      get () {
        return this.message.message.replace(/\*([^*]*)\*/g, (match, group) => {
          return '<b>' + group + '</b>'
        })
      }
    }
  },
  methods: {
    scrollToMessage () {
      this.$emit('scrollToMessage')
    }
  }
}
</script>

<style lang="scss">
.found-message {
  padding: 1em 0.5em;
  border-bottom: 1px solid #e1e4e8;
  font-size: 14px;
  font-family: "Nunito", sans-serif;
  cursor: pointer;
}

.found-message:hover {
  background-color: #eeeeeece;
  box-shadow: 0px 1px 5px #e1e4e8;
}

.found-message .message-timestamp {
  font-size: 12px;
  padding-bottom: 2px;
}
</style>
