<template>
    <b-modal
    :active="isComponentModalActive"
    @close="closeModal"
    has-modal-card
    style="width: auto;"
    >
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Opções</p>
            </header>
            <section class="modal-card-body">
                <b-field>
                  <b-button type="is-primary" @click="startChat" expanded>Iniciar conversa</b-button>
                </b-field>
                <b-field>
                    <b-button type="is-info" v-if="participant.is_admin" @click="changeParticipantPermission('is_admin', false)" expanded>Remover da lista de admins</b-button>
                    <b-button type="is-info" v-else @click="changeParticipantPermission('is_admin', true)" expanded>Promover para administrador</b-button>
                </b-field>
                <b-field>
                    <b-button type="is-info" v-if="participant.send_message_blocked" @click="changeParticipantPermission('send_message_blocked', false)" expanded>Desbloquear envio de mensagens</b-button>
                    <b-button type="is-info" v-else @click="changeParticipantPermission('send_message_blocked', true)" expanded>Bloquear envio de mensagens</b-button>
                </b-field>
                <b-field>
                    <b-button type="is-danger" @click="removeParticipant" expanded>Remover do grupo</b-button>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <button class="button" type="button" @click="closeModal">
                    Fechar
                </button>
            </footer>
        </div>
    </b-modal>
</template>
<script>
export default {
  name: 'ModalChatParticipantOptions',
  props: {
    participant: {
      type: Object,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal () {
      this.$emit('closeModal')
    },
    changeParticipantPermission (item, value) {
      switch (item) {
        case 'is_admin':
          this.$emit('changeParticipantPermission', {
            ...this.participant,
            is_admin: value
          })
          break
        case 'send_message_blocked':
          this.$emit('changeParticipantPermission', {
            ...this.participant,
            send_message_blocked: value
          })
          break
        default:
          break
      }
      this.closeModal()
    },
    removeParticipant () {
      this.$emit('removeParticipant', this.participant.attendant_id)
      this.closeModal()
    },
    startChat () {
      this.$emit('startChat', this.participant)
      this.closeModal()
    }
  }
}
</script>
