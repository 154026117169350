<template>
  <div>
    <section>
      <b-modal :active="isDragging" @close="closeDrag" full-screen has-modal-card>
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Adicionar Arquivo</p>
            <div class="buttons">
              <b-button type="is-danger" @click="removeItems('cancel')">
                Cancelar
              </b-button>
              <b-button type="is-primary" :disabled="itemsAdded < 1 || itemsAdded > 30" @click="sendMessageFromDrag">
                Enviar
              </b-button>
            </div>
          </header>
          <section class="modal-card-body" style="overflow: visible !important;">
            <div class="uploadBox">
              <form role="form" enctype="multipart/form-data">
                <div class="uploadBoxMain" v-if="!itemsAdded">
                  <div class="form-group">
                    <div class="dropArea" @ondragover="onChange" :class="dragging ? 'dropAreaDragging' : ''"
                      @dragenter="dragging = true" @dragend="dragging = false" @dragleave="dragging = false">
                      <p><b-icon icon="upload" size="is-large"></b-icon></p>
                      <h3>Solte os arquivos aqui</h3>
                      <input type="file" id="items" name="items[]" required multiple @change="onChange">
                      <p class="help-block">ou clique para selecionar</p>
                    </div>
                  </div>
                </div>
                <div class="uploadBoxMain" v-else>
                  <b-button style="margin: 15px 0;" type="is-danger" outlined @click="removeItems('remove')">
                    Remover Arquivos
                  </b-button>

                  <b-carousel :autoplay="false" indicator-custom :indicator-inside="false" :overlay="gallery"
                    @click="switchGallery(true)">
                    <b-carousel-item v-for="(image, i) in itemsPath" :key="i">
                      <a class="image full-image">
                        <img v-if="getImgUrl(i) !== false" style="height: 300px !important;" :src="getImgUrl(i)"
                          :alt="image.name">
                        <div v-else class="imageNotView">
                          <p><b-icon icon="eye-off" size="is-large"></b-icon></p>
                          <h3>Não é possível visualizar o arquivo</h3>
                          <p class="help-block">{{ image.name }}</p>
                        </div>
                      </a>
                      <!-- Input para enviar mensagem juntamente com a foto -->
                      <b-input style="margin: 15px;" v-model="itemsMessages[i]" placeholder="Digite a mensagem"></b-input>
                    </b-carousel-item>
                    <span v-if="gallery" @click="switchGallery(false)" class="modal-close is-large" />
                    <template #indicators="props">
                      <figure class="al image" :draggable="false">
                        <img v-if="getImgUrl(props.i) !== false" :draggable="false" :src="getImgUrl(props.i)"
                          :title="props.i">
                        <div v-else>
                          <b-icon icon="eye-off" size="is-large"></b-icon>
                          <p>Não é possível visualizar o arquivo</p>
                          <p>{{ getImgName(props.i) }}</p>
                        </div>
                      </figure>
                    </template>
                  </b-carousel>
                </div>
              </form>
            </div>
          </section>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-expressions */
/* eslint-disable camelcase */
import { post } from '../../utils/api'

export default {
  name: 'DragAndDropFiles',
  props: {
    selectedRoom: {
      type: Object,
      required: true
    },
    isDragging: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      dragging: false,
      items: [],
      itemsMessages: [],
      itemsAdded: '',
      itemsNames: [],
      itemsPath: [],
      formData: '',
      isLoaderVisible: false,
      gallery: false
    }
  },
  methods: {
    blobToBase64(blob) {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result)
        }
      })
    },
    async sendMessageFromDrag() {
      if (!this.selectedRoom.attendant_id) {
        this.$buefy.dialog.alert({
          title: 'Erro ao Enviar Arquivo',
          message: 'O <b>ticket</b> não foi selecionado. Selecione o <b>ticket</b> antes de enviar o(s) arquivo(s)!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
          onConfirm: () => this.removeItems('cancel')
        })
      } else {
        if (this.itemsPath.length > 0) {
          for (const i in this.itemsPath) {
            const base64 = await this.blobToBase64(this.itemsPath[i])
            const file_base64 = base64.substr(base64.indexOf(',') + 1)
            const extension = this.getExtensionFile(this.itemsPath[i].name)
            /* let contentMessage = ''
            if (extension !== 'png' && extension !== 'jpeg' && extension !== 'jpg') {
              contentMessage = `${this.itemsPath[i].name.split('.')[0]}.${extension}`
            } */
            post('messages/user_message', {
              // message: this.itemsMessages[i] !== '' && this.itemsMessages[i] !== undefined ? this.itemsMessages[i] : contentMessage,
              // message: contentMessage,
              message: '',
              ticket_id: this.selectedRoom.id,
              file_base64,
              file_mimetype: this.itemsPath[i].type,
              file_name: `${this.itemsPath[i].name.split('.')[0]}.${extension}`,
              reply_message_id: null
            })
          }
        }
        this.removeItems('cancel')
      }
    },
    getExtensionFile(file) {
      return file.split('.').pop()
    },
    switchGallery(value) {
      this.gallery = value
      if (value) {
        return document.documentElement.classList.add('is-clipped')
      } else {
        return document.documentElement.classList.remove('is-clipped')
      }
    },
    getImgUrl(value) {
      const url = URL.createObjectURL(this.itemsPath[value])
      const extension = this.itemsPath[value].name.split('.').pop()
      if (extension !== 'png' && extension !== 'jpeg' && extension !== 'jpg') {
        return false
      } else {
        return url
      }
    },
    getImgName(value) {
      return this.itemsPath[value].name
    },
    onChange(e) {
      this.formData = new FormData()
      const files = e.target.files || e.dataTransfer.files
      this.itemsAdded = files.length
      for (const x in files) {
        if (!isNaN(x)) {
          this.items = e.target.files[x] || e.dataTransfer.files[x]
          this.itemsNames[x] = files[x].name
          this.itemsPath[x] = files[x]
          this.formData.append('items[]', this.items)
        }
      }
    },
    removeItems(button) {
      if (button === 'cancel') {
        this.items = ''
        this.itemsAdded = ''
        this.itemsNames = []
        this.itemsSizes = []
        this.itemsPath = []
        this.itemsTotalSize = ''
        this.itemsMessages = []
        this.dragging = false
        this.closeDrag()
      } else {
        this.items = ''
        this.itemsAdded = ''
        this.itemsNames = []
        this.itemsSizes = []
        this.itemsPath = []
        this.itemsTotalSize = ''
        this.itemsMessages = []
        this.dragging = false
      }
    },
    closeDrag() {
      this.$emit('closeDrag', this.selectedRoom)
    }
  }
}
</script>

<style lang="scss" scoped>
.min-300 {
  min-width: 300px;
}

.modal .animation-content .modal-card {
  overflow: visible !important;
}

.modal-card-body {
  overflow: visible !important;
}

.full-image img {
  width: auto !important;
  height: 400px !important;
  padding: 0 22.5% !important;
}

.indicator-item {
  width: 80px !important;
  height: 80px !important;
}

.al {
  width: 100px;
}

.is-active .al img {
  border: 1px solid #fff;
  filter: grayscale(0%);
  object-fit: contain;
}

.al img {
  border: 1px solid transparent;
  filter: grayscale(100%);
  object-fit: contain;
}

.uploadBox {
  position: relative;
  background: #eee;
  padding: 0 1em 1em 1em;
  /* margin: 1em; */
}

/* .uploadBox h3 {
        padding-top: 1em;
    } */

.uploadBox .uploadBoxMain {
  position: relative;
  margin-bottom: 1em;
  margin-right: 1em;
}

/* Drag and drop */
.uploadBox .dropArea {
  position: relative;
  width: 100%;
  height: 300px;
  border: 5px dashed #00ADCE;
  text-align: center;
  font-size: 2em;
  padding-top: 80px;
}

.uploadBox .dropArea input {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}

/* End drag and drop */

.imageNotView {
  position: relative;
  width: 100%;
  height: 300px;
  text-align: center;
  font-size: 2em;
  padding-top: 80px;
}

/* Loader */
.uploadBox .loader {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  opacity: 0.9;
}

.uploadBox .loaderImg {
  border: 9px solid #eee;
  border-top: 9px solid #00ADCE;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* End Loader */

.uploadBox .errorMsg {
  font-size: 2em;
  color: #a94442;
}

.uploadBox .successMsg {
  font-size: 2em;
  color: #3c763d;
}
</style>
