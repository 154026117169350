<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Enviar mensagem predefinida</p>
          </header>
          <section class="modal-card-body" style="overflow-y: auto !important">
            <div v-if="allDefaultMessages.length > 0" class="messages-box">
              <div
                class="card"
                v-for="message in allDefaultMessages"
                :key="message.id"
              >
                <div class="card-content" :class="{'card-content-details': message.displayDetails}">
                  <div class="content">
                    <div class="columns is-vcentered">
                      <div class="column">{{ message.title }}</div>
                      <div class="column is-one-fifth">
                        <b-button
                          class="is-fullwidth"
                          icon-right="comment-eye-outline"
                          outlined
                          @click="openMessage(message)"
                        >
                          Visualizar
                        </b-button>
                      </div>
                      <div class="column is-one-fifth">
                        <b-button
                          class="is-fullwidth"
                          icon-right="send"
                          type="is-success"
                          outlined
                          @click="sendDefaultMessage(message)"
                        >
                          Enviar
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-show="message.displayDetails" class="card-details">
                  <p v-if="message.message">
                    <b>Mensagem: </b>
                    {{ message.message }}
                  </p>
                  <p v-if="message.file_name">
                    <b>Arquivo: </b>
                    <a @click="handleDownloadFile(message)">{{ message.file_name }}</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">
              Fechar
            </button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import mixin from '../../utils/mixins'
// import { get } from '../../utils/api'
import axios from 'axios'
import { mapActions } from 'vuex'

export default {
  name: 'ModalSendDefaultMessages',
  props: {
    defaultMessages: {
      type: Array,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      defaultMessagesData: []
    }
  },
  mixins: [mixin],
  computed: {
    allDefaultMessages: {
      get () {
        return this.defaultMessagesData.map(el => {
          if (!el.hasOwnProperty('displayDetails')) {
            el.displayDetails = false
          }
          return el
        })
      },
      set (message) {
        this.defaultMessagesData = this.defaultMessagesData.map(el => {
          if (message.id === el.id) {
            el = message
          }
          return el
        })
      }
    }
  },
  created () {
    this.defaultMessagesData = JSON.parse(JSON.stringify(this.defaultMessages))
  },
  methods: {
    ...mapActions([
      'fetchPredefinedMessageDetail'
    ]),
    sendDefaultMessage (defaultMessage) {
      this.$emit('sendDefaultMessage', defaultMessage)
    },
    async openMessage (defaultMessage) {
      const response = await this.fetchPredefinedMessageDetail(defaultMessage.id)
      const message = response.data
      const url = await this.getUrl(message)
      console.log(url)

      defaultMessage.message = message.message
      defaultMessage.file_base64 = message.file_base64
      defaultMessage.file_mimetype = message.file_mimetype
      defaultMessage.file_name = message.file_name
      defaultMessage.displayDetails = !defaultMessage.displayDetails

      this.allDefaultMessages = defaultMessage
    },
    async handleDownloadFile (defaultMessage) {
      const response = await this.fetchPredefinedMessageDetail(defaultMessage.id)
      const message = response.data
      const url = await this.getUrl(message)

      await axios.get(url, { responseType: 'blob' })
        .then(response => {
          const blob = new Blob([response.data], { type: message.file_mimetype })
          const link = document.createElement('a')
          link.href = URL.createObjectURL(blob)
          link.download = message.file_name
          link.click()
          URL.revokeObjectURL(link.href)
        }).catch(console.error)
    },
    async getUrl (defaultMessage) {
      const data = {
        base64: defaultMessage.file_base64,
        fileName: defaultMessage.file_name,
        fileType: defaultMessage.file_mimetype
      }
      const response = await this.base64ToBlob(data)

      const blob = new Blob([response], {
        type: defaultMessage.file_mimetype
      })

      return window.URL.createObjectURL(blob)
    },
    async base64ToBlob (filebase64) {
      const response = await fetch(
        `data:${filebase64.fileType};base64,${filebase64.base64}`
      )
      const blob = await response.blob()
      return new File([blob], filebase64.fileName, {
        type: filebase64.fileType
      })
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .card:not(:last-child) {
    margin-bottom: 0.5rem;
  }

  .messages-box {
    max-height: 20rem;
    overflow-y: auto;
  }

  .card-details {
    border-top: 0px;
    border-width: 1px;
    border-color: rgba(24, 28, 33, 0.06);
    background-color: hsl(0deg, 0%, 98%);
    padding: 1rem 1.5rem;
  }

  .card-content-details {
    border-radius: 4px;
    border-bottom: 1px solid rgba(24, 28, 33, 0.06);
  }
</style>
