<template>
  <div>
    <section>
      <b-modal :active="isComponentModalActive" @close="closeModal" has-modal-card>
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{ modalTitle }}</p>
          </header>
          <section class="modal-card-body">
            <b-field label="Número de origem">
              <b-select
                placeholder=""
                v-model="wwPhoneSelected"
                expanded
              >
                <option
                  v-for="setting in getSettings"
                  :value="setting.phone"
                  :key="setting.phone"
                >
                  {{ setting.name ? setting.name + ' - ' + setting.phone : setting.phone }}
                </option>
              </b-select>
            </b-field>
            <b-field label="Contatos disponíveis">
              <b-autocomplete
                class="min-300"
                v-model="filter"
                placeholder="Selecione um contato"
                :keep-first="false"
                :open-on-focus="true"
                :data="contacts"
                field="nameAndPhone"
                :loading="isFetching"
                @typing="getAsyncData"
                @select="option => (contactSelected = option)"
                :clearable="true"
              />
            </b-field>
            <b-message v-if="contactTicket && contactTicket.length > 0 && departmentSelected" class="warning-message" type="is-warning">
              O contato possui ticket aberto no departamento {{ departmentSelected.name }} no número selecionado.
            </b-message>
            <b-field label="Departamento">
              <b-select
                placeholder=""
                v-model="departmentSelected"
                expanded
                :disabled="(contactTicket && contactTicket.length > 0) || !contactSelected || !wwPhoneSelected"
              >
                <option
                  v-for="dpt in getDepartments"
                  :value="dpt"
                  :key="dpt.id"
                >
                  {{ dpt.name }}
                </option>
              </b-select>
            </b-field>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
            <button class="button is-primary" @click="selectContact">Selecionar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import debounce from 'debounce'
import { mapActions } from 'vuex'
import { API_TYPE } from '../../utils/constants'

export default {
  name: 'ModalSelectTicket',
  props: {
    modalTitle: {
      type: String,
      required: true
    },
    isComponentModalActive: {
      type: Boolean,
      required: true
    },
    tickets: {
      type: Array,
      required: true
    },
    settings: {
      type: Array,
      required: true
    },
    departments: {
      type: Array,
      required: true
    },
    contactTicket: {
      type: Array,
      required: false
    },
    onlyWwebjs: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    getSettings () {
      if (this.onlyWwebjs) {
        return this.settings.filter(setting => setting.api_type === API_TYPE.WWEBJS)
      } else {
        return this.settings
      }
    },
    getDepartments () {
      return this.departments.filter(d => d.enabled)
    }
  },
  created () {
    this.wwPhoneSelected = this.settings[0] ? this.settings[0].phone : null
    this.departmentSelected = (this.departments.length === 1 && this.departments[0]) ? this.departments[0] : null
  },
  data () {
    return {
      contactSelected: null,
      filter: '',
      contacts: [],
      wwPhoneSelected: null,
      isFetching: false,
      departmentSelected: null
    }
  },
  watch: {
    wwPhoneSelected (phone) {
      if (this.contactSelected) {
        this.$emit('findContactTicket', { contact: this.contactSelected, ww_phone: phone })
      }
    },
    contactSelected (contact) {
      if (this.wwPhoneSelected) {
        this.$emit('findContactTicket', { contact: contact, ww_phone: this.wwPhoneSelected })
      }
    },
    contactTicket (ticket) {
      if (ticket.length > 0) {
        const dpt = this.departments.filter(el => el.id === ticket[0].department_id)
        if (dpt && dpt.length === 1) {
          this.departmentSelected = dpt[0]
        }
      }
    }
  },
  methods: {
    ...mapActions(['fetchContactsWithFilter']),
    getAsyncData: debounce(function (name) {
      this.contacts = []
      if (!name.length) {
        return
      }
      this.isFetching = true
      this.fetchContactsWithFilter({ limit: 5, filter: name })
        .then(({ data: { results } }) => {
          results.forEach(item =>
            this.contacts.push({
              ...item,
              nameAndPhone: `${item.name} - ${item.phone}`
            })
          )
        })
        .catch(error => {
          this.contacts = []
          throw error
        })
        .finally(() => {
          this.isFetching = false
        })
    }, 500),
    selectContact () {
      if (!this.contactSelected || !this.departmentSelected || !this.wwPhoneSelected) {
        this.$buefy.dialog.alert({
          title: 'Contato não selecionado',
          message: 'É necessário selecionar um <b>número, contato e departamento</b> caso queira prosseguir!',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true
        })

        return
      }
      this.emitContactSelected({
        contact: this.contactSelected,
        department: this.departmentSelected,
        ww_phone: this.wwPhoneSelected
      })
    },
    emitContactSelected (data) {
      this.$emit('contactSelected', data)
    },
    closeModal () {
      this.$emit('closeModal')
    }
  }
}
</script>

<style lang="scss" scoped>
  .min-300 {
    min-width: 300px;
  }

  .modal .animation-content .modal-card {
    overflow: visible !important;
  }

  .modal-card-body {
    overflow: visible !important;
  }
</style>
