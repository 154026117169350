<template>
  <div>
    <section>
      <b-modal :active="true" @close="closeModal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Encarteirar contato</p>
          </header>
          <section class="modal-card-body">
            <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false"></b-loading>
            <label class="label">Contato</label>
            <div class="card">
              <div class="card-content">
                <div class="media">
                  <div class="media-left" v-if="contactImage">
                    <b-image class="is-48x48" :src="contactImage" :rounded="true">
                    </b-image>
                  </div>
                  <div class="media-content">
                    <p class="title is-4">{{ ticket.contact.id }} - {{ ticket.contact.name }}</p>
                    <p class="subtitle is-6">{{ ticket.contact.phone }}</p>
                  </div>
                </div>
              </div>
            </div>
            <hr>
            <label class="label">Encarteiramentos do contato</label>
            <div class="card mb-1" v-for="registry in getComputedContactsAttendants">
              <div class="card-content">
                <b-button size="is-small is-pulled-right" :loading="isSettingAttendant" v-if="!registry.myContact" @click="setContactAttendant(registry.department.id)">
                  Encarteirar para mim
                </b-button>
                <p class="title is-6 is-pulled-right has-text-success" v-else>Já vinculado a mim</p>
                <div class="media">
                  <div class="media-content">
                    <p class="title is-6">{{ registry.department.id }} - {{ registry.department.name }}</p>
                    <p class="subtitle is-6" v-if="registry.attendant">{{ registry.attendant.name }}</p>
                    <p class="subtitle is-6 has-text-success" v-else>Sem atendente vinculado</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button" type="button" @click="closeModal">Fechar</button>
          </footer>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { get } from '@/utils/api'

export default {
  name: 'ModalSetContactAttendant',
  props: {
    ticket: {
      type: Object,
      required: true
    },
    attendant_id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      contactAttendants: [],
      contactImage: null,
      isLoading: false,
      isSettingAttendant: false,
      departments: [],
      settingAttendant: {}
    }
  },
  computed: {
    ...mapGetters([
      'allDepartmentsEnabled'
    ]),
    getComputedContactsAttendants() {
      return this.departments.map(department => {
        const departmentAttendant = this.contactAttendants.find(contactAttendant => contactAttendant.department_id === department.id)

        if (departmentAttendant) {
          return {
            id: departmentAttendant.id,
            attendant: departmentAttendant.attendant,
            department: departmentAttendant.department,
            class: departmentAttendant.attendant_id === this.attendant_id ? 'has-text-info' : '',
            myContact: departmentAttendant.attendant_id === this.attendant_id
          }
        }

        return {
          id: null,
          attendant: null,
          department: department,
          class: 'has-text-success',
          myContact: false
        }
      })
    },
  },
  methods: {
    ...mapActions([
      'fetchAndResturnContactAttendants',
      'fetchDepartments',
      'addContactToAttendant',
      'fetchCurrentAttendantDepartmentsAndReturn'
    ]),
    async getImage(id) {
      if (!id) return
      const contactImage = await get(`contacts/image/${id}`)
      return contactImage ? `data:image/png;base64,${contactImage.data}` : ''
    },
    closeModal() {
      this.$emit('closeModal')
    },
    async setContactAttendant(department_id) {
      this.isSettingAttendant = true;
      try {
        await this.addContactToAttendant({
          contact_id: this.ticket.contact_id,
          department_id,
          attendant_id: this.attendant_id
        });

        this.contactAttendants = await this.fetchAndResturnContactAttendants(this.ticket.contact_id);
      } catch (error) {
        console.error(error)
        this.$buefy.dialog.confirm({
          title: 'Erro ao encarteirar contato',
          message: 'Ocorreu um erro ao encarteirar o contato. Erro: ' + error.response ? error.response.data.message : error.message,
          confirmText: 'Ok',
          type: 'is-danger',
          hasIcon: true,
          cancelText: 'Fechar',
        })
      } finally {
        this.isSettingAttendant = false;
      }
    }
  },
  async created() {
    this.isLoading = true;
    try {
      const attendantDepartments = await this.fetchCurrentAttendantDepartmentsAndReturn()
      this.departments = attendantDepartments.map(department => department.department);
      this.contactImage = await this.getImage(this.ticket.contact_id);

      this.contactAttendants = await this.fetchAndResturnContactAttendants(this.ticket.contact_id);
    } catch (error) {
      this.closeModal();
      console.error(error)
      this.$buefy.dialog.confirm({
        title: 'Erro ao carregar dados',
        message: 'Ocorreu um erro ao carregar os dados necessários. Por favor recarregue a página e tente novamente!',
        confirmText: 'Ok',
        type: 'is-danger',
        hasIcon: true,
        cancelText: 'Fechar',
      })
    } finally {
      this.isLoading = false;
    }
  },
}
</script>

<style lang="scss" scoped>
  .modal .animation-content .modal-card {
    overflow: visible !important;
  }

  .modal-card-body {
    overflow: visible !important;
  }

  .modal-card-foot {
    justify-content: flex-end;
  }

  .vac-room-item-open {
    border-color: aquamarine;
    border-width: 1px;
    border-style: solid;
    // animation: blinker 1.5s linear infinite;
  }

  .vac-container-scroll__margin-bottom {
    margin-bottom: 60px;
  }

  .vac-box-footer__hidden {
    display: none !important;
  }

  .select-attendant-div {
    background-color: aquamarine;
  }

  @keyframes blinker {
    50% {
      opacity: 30%;
    }
  }
</style>
